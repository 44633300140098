import { createI18n } from "vue-i18n";
import messages from "../language";

const i18nTemp = createI18n({
  // 设置默认语言
  locale: localStorage.getItem("locale") || "zh", //语言标识
  messages,
  fallbackLocale: "zh", //在首选语言缺少翻译时选择要使用的语言。
  formatFallbackMessages: true, //如果在message中找不到相应的键值将回退到原本的语言
});
const i18n = {
  install(app) {
    if (!localStorage.getItem("locale")) {
      if (
        (navigator.language || navigator.browserLanguage)
          .toLowerCase()
          .includes("zh-cn")
      ) {
        localStorage.setItem("locale", "zh");
      } else {
        localStorage.setItem("locale", "en");
      }
    }
    app.use(i18nTemp);
  },
};

export function $t(args, argArray) {
  return i18nTemp.global.tc.call(i18nTemp, args, argArray);
}

export default i18n;
