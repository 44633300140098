import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.splice.js";
import { h } from "vue";
import PillsLayout from "./PillsLayout";
import TabsLayout from "./TabsLayout";
export default {
  name: "tabs",
  components: {
    TabsLayout: TabsLayout,
    PillsLayout: PillsLayout,
    TabItemContent: {
      props: ["tab"],
      render: function render() {
        return h("div", [this.tab.$slots.title || this.tab.title]);
      }
    }
  },
  props: {
    type: {
      type: String,
      "default": "",
      validator: function validator(value) {
        var acceptedValues = ["", "primary", "info", "success", "warning", "danger"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Tabs type (primary|info|danger|default|warning|success)"
    },
    pills: {
      type: Boolean,
      "default": true,
      description: "Whether tabs are pills"
    },
    circle: {
      type: Boolean,
      "default": false,
      description: "Whether tabs are circle"
    },
    fill: {
      type: Boolean,
      "default": true,
      description: "Whether to fill each tab"
    },
    activeTab: {
      type: String,
      "default": "",
      description: "Default active tab name"
    },
    tabNavWrapperClasses: {
      type: [String, Object],
      "default": "",
      description: "Tab Nav wrapper (div) css classes"
    },
    tabNavClasses: {
      type: [String, Object],
      "default": "",
      description: "Tab Nav (ul) css classes"
    },
    tabContentClasses: {
      type: [String, Object],
      "default": "",
      description: "Tab content css classes"
    },
    icons: {
      type: Boolean,
      description: "Whether tabs should be of icon type (small no text)"
    },
    centered: {
      type: Boolean,
      description: "Whether tabs are centered"
    },
    value: {
      type: String,
      description: "Initial value (active tab)"
    }
  },
  provide: function provide() {
    return {
      addTab: this.addTab,
      removeTab: this.removeTab
    };
  },
  data: function data() {
    return {
      tabs: [],
      activeTabIndex: 0
    };
  },
  computed: {
    layoutComponent: function layoutComponent() {
      return this.pills ? "pills-layout" : "tabs-layout";
    },
    slotData: function slotData() {
      return {
        activeTabIndex: this.activeTabIndex,
        tabs: this.tabs
      };
    }
  },
  methods: {
    findAndActivateTab: function findAndActivateTab(title) {
      var tabToActivate = this.tabs.find(function (t) {
        return t.title === title;
      });

      if (tabToActivate) {
        this.activateTab(tabToActivate);
      }
    },
    activateTab: function activateTab(tab) {
      if (this.handleClick) {
        this.handleClick(tab);
      }

      this.deactivateTabs();
      tab.active = true;
      this.activeTabIndex = this.tabs.findIndex(function (t) {
        return t.active;
      });
    },
    deactivateTabs: function deactivateTabs() {
      this.tabs.forEach(function (tab) {
        tab.active = false;
      });
    },
    addTab: function addTab(tab) {
      if (this.activeTab === tab.name) {
        tab.active = true;
      }

      this.tabs.push(tab);
    },
    removeTab: function removeTab(tab) {
      var tabs = this.tabs;
      var index = tabs.indexOf(tab);

      if (index > -1) {
        tabs.splice(index, 1);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      if (_this.value) {
        _this.findAndActivateTab(_this.value);
      } else {
        if (_this.tabs.length > 0) {
          _this.activateTab(_this.tabs[0]);
        }
      }
    });
  },
  watch: {
    value: function value(newVal) {
      this.findAndActivateTab(newVal);
    }
  }
};