import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "row"
};
var _hoisted_2 = {
  "class": "col-xl-3 col-lg-6"
};
var _hoisted_3 = {
  "class": "col-xl-3 col-lg-6"
};
var _hoisted_4 = {
  "class": "col-xl-3 col-lg-6"
};
var _hoisted_5 = {
  "class": "col-xl-3 col-lg-6"
};
var _hoisted_6 = {
  "class": "col-xl-3 col-lg-6 mt-3"
};
var _hoisted_7 = {
  "class": "col-xl-3 col-lg-6 mt-3"
};
var _hoisted_8 = {
  "class": "container-fluid mt--7"
};
var _hoisted_9 = {
  "class": "row mt-5"
};
var _hoisted_10 = {
  "class": "col-xl-8 mb-5 mb-xl-0"
};
var _hoisted_11 = {
  "class": "col-xl-4"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_stats_card = _resolveComponent("stats-card");

  var _component_base_header = _resolveComponent("base-header");

  var _component_page_visits_table = _resolveComponent("page-visits-table");

  var _component_social_traffic_table = _resolveComponent("social-traffic-table");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_base_header, {
    type: "gradient-success",
    "class": "pb-6 pb-8 pt-5 pt-md-8"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_stats_card, {
        title: _ctx.$t('label.thirtyDayPeolpeNum'),
        type: "gradient-red",
        "sub-title": $data.dashboardStatistics.thirtyDayPeolpeNum,
        icon: "ni ni-active-40",
        "class": "mb-4 mb-xl-0"
      }, null, 8, ["title", "sub-title"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_stats_card, {
        title: _ctx.$t('label.totalPeolpeNum'),
        type: "gradient-orange",
        "sub-title": $data.dashboardStatistics.totalPeolpeNum,
        icon: "ni ni-chart-pie-35",
        "class": "mb-4 mb-xl-0"
      }, null, 8, ["title", "sub-title"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_stats_card, {
        title: _ctx.$t('label.totalBonus'),
        type: "gradient-green",
        "sub-title": $data.dashboardStatistics.totalBonus,
        icon: "ni ni-money-coins",
        "class": "mb-4 mb-xl-0"
      }, null, 8, ["title", "sub-title"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_stats_card, {
        title: _ctx.$t('label.totalSbiBonus'),
        type: "gradient-info",
        "sub-title": $data.dashboardStatistics.totalSbiBonus,
        icon: "ni ni-chart-bar-32",
        "class": "mb-4 mb-xl-0"
      }, null, 8, ["title", "sub-title"])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_stats_card, {
        title: _ctx.$t('label.totalSteemBonus'),
        type: "gradient-green",
        "sub-title": $data.dashboardStatistics.totalSteemBonus,
        icon: "ni ni-money-coins",
        "class": "mb-4 mb-xl-0"
      }, null, 8, ["title", "sub-title"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_stats_card, {
        title: _ctx.$t('label.totalUvfBonus'),
        type: "gradient-info",
        "sub-title": $data.dashboardStatistics.totalUvfBonus,
        icon: "ni ni-chart-bar-32",
        "class": "mb-4 mb-xl-0"
      }, null, 8, ["title", "sub-title"])])])];
    }),
    _: 1
  }), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_page_visits_table)]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_social_traffic_table)])])])]);
}