import "core-js/modules/es.function.name.js";
import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["id", "disabled", "value"];
var _hoisted_2 = ["for"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["custom-control custom-radio", [$options.inlineClass, {
      disabled: $props.disabled
    }]])
  }, [_withDirectives(_createElementVNode("input", {
    id: $data.cbId,
    "class": "custom-control-input",
    type: "radio",
    disabled: $props.disabled,
    value: $props.name,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $options.model = $event;
    })
  }, null, 8, _hoisted_1), [[_vModelRadio, $options.model]]), _createElementVNode("label", {
    "for": $data.cbId,
    "class": "custom-control-label"
  }, [_renderSlot(_ctx.$slots, "default")], 8, _hoisted_2)], 2);
}