const zh = {
  login: {
    login: "登录",
    cancel: "取消",
    loginout: "退出",
  },
  menu: {
    lucknum: "幸运数字",
    airdrop: "幸运空投",
    lucknumairdroprank: "空投榜单",
    blog: "博客",
    communities: "社区",
    friends: "朋友",
    friends1: "朋友",
    myCommunities: "我的社区",
    myCommunities1: "我的社区",
    post: "发文",
    home: "博客",
    article: "文章",
    community: "社区",
    article1: "文章",
    communityIndex: "社区",
    post1: "发文",
    friend: "朋友",
    friend1: "朋友",
    pointsmarket: "积分市场",
    setting: "个人设置",
    wallet: "钱包",
    clubentrance: "俱乐部入口",
    marketuvfexchange: "UVF 兑换",
    post2: "编辑",
    reg: "注册",
    verifyemail: "注册验证",
    program: "项目",
    sap: "我的SAP记录",
    sappost: "Sap 发文",
    tools: "工具箱",
    removeAuthorization: "移除授权",
    authorityManagement: "权限管理",
  },
  label: {
    thirtyDayPeolpeNum: "30天内的参与人次",
    totalPeolpeNum: "总参与人次",
    totalBonus: "累计发放(SBD)",
    totalSbiBonus: "累计发放(SBI)",
    totalUvfBonus: "累计发放(UVF)",
    totalSteemBonus: "累计发放(Steem)",
    currentWeekPeopleNum: "本周参与次",
    lastWeekPeopleNum: "上周参与人次",
    currentWeekBonus: "本周奖金",
    lastWeekBonus: "上周奖金",
    activityList: "活动列表",
    bonusRanking: "奖金排行榜",
    postKey: "Post Key",
    activeKey: "Active Key",
    comfirmTrans: "确认交易",
    UserLogin: "用户登录",
    SignInWith: "通过下面方式登录", //Sign in with
    OrSignInWithCredentials: "通过用户名密码登录", //Or sign in with credentials
    InputUserName: "输入用户名", //Input UserName
    InputPassWord: "输入PostKey", //Input PostKey
    InputActivePassWord: "输入ActiveKey", //Input ActiveKey
    SignIn: "登录",
    RememberMe: "保持登录状态",
    UseSteemKeychain: "使用SteemKeychain登录",
    sbiBalance: "SBI 余额",
    balance: "余额",
    pleaseLogin: "请先登录",
    myAirDrop: "我的空投",
    language: "语言",
    node: "API 节点",
    creatTime: "发布时间",
    monthsago: "月前",
    hoursago: "小时前",
    minutesago: "分钟前",
    daysago: "天前",
    weeksago: "周前",
    justnow: "刚刚",
    powerDownDaysAfter: "下一次的Power Down计划发生在 {time} 天后",
    powerDownHoursAfter: "下一次的Power Down计划发生在 {time} 小时后",
    powerDownMinutesAfter: "下一次的Power Down计划发生在 {time} 分钟后",
    publish: "发布",
    postIntro: "支持Markdown语法",
    addTag: "添加标签",
    postTitle: "标题",
    postBody: "正文",
    postTag: "标签",
    reply: "回复",
    delete: "删除",
    author: "作者",
    emoj: "表情",
    image: "图片",
    allPost: "所有文章",
    communites: "社区",
    communityTitle: "名字",
    communityAbout: "关于",
    communityDescription: "介绍",
    communityRule: "规则",
    subscribers: "订阅者",
    posters: "作者",
    posts: "文章",
    helloClubFriend: "你好! 俱乐部的朋友们",
    lucknumAirdropIntro:
      "这是Boylikegirl俱乐部幸运数字活动的空投接收地点。祝您供应愉快。空投不常有，但时不时会有",
    deadline: "截止时间",
    profileImage: "头像",
    displayName: "显示名称",
    about: "关于",
    location: "位置",
    website: "网站",
    likeid: "Like Id",
    communityAvatarUrl: "社区头像",
    transfer: "转账",
    cancel: "取消",
    transferSend: "转",
    transferRevice: "收到",
    transferTo: "到",
    transferFrom: "从",
    date: "时间",
    title: "标题",
    memo: "备注",
    points: "积分",
    registerLiker: "注册 Liker",
    SteemWallet: "Steem 钱包",
    PointsWallet: "Points 钱包",
    uploadImageProgress: "图片上传进度",
    from: "从",
    to: "到",
    amount: "金额",
    comfirmPowerUp: "Convert to STEEM POWER",
    comfirmPowerUpContent:
      "Influence tokens which give you more control over post payouts and allow you to earn on curation rewards.<br /> STEEM POWER is non-transferable and requires 1 month (4 payments) to convert back to Steem.",
    comfirmPowerDown: "Power Down",
    comfirmPowerDownContent: "",
    blog: "博客",
    post: "文章",
    Delegations: "代理",
    incoming: "代入",
    outgoing: "代出",
    expiring: "撤销",
    Delegator: "代理人",
    Delegatee: "被代理人",
    filter: "过滤",
    availableSP: "有效SP",
    delegate: "代理",
    currentReward: "你的当前奖励",
    pointsMarketIntro: "积分市场，允许用户以更多方式和可能性使用积分",
    uvf_title: "点赞基金",
    uvf_intro:
      "点赞基金是一个,赞助人和被赞助人联合而成的点赞基金,当赞助者将Steem转账到uvf时,赞助者和被赞助者每日都将获得赞助金额的20倍点赞.<br />赞助金额可以累积",
    marketuvfexchange: "UVF 兑换",
    pointExhangeUvfRatio: "积分兑换UVF比例",
    signature: "签名",
    blogPostRewards: "文章奖金",
    commentPostRewards: "回复奖金",
    advancedSettings: "高级设置",
    whoReceiveAnyRewards: "谁将得到奖励?",
    add: "添加",
    email: "邮箱",
    regIntro: "请珍惜注册资源,不要注册多号",
    programIntro: "俱乐部优质项目",
    sap_title: "定时发布文章",
    sap_intro:
      "定时发布文章,帮助你预先配置好需要发布的文章,按照您设定的时间将文章发布出去.",
    sap_remark: "最长设置7天的文章",
    sapAuthorizeTips: "使用定时发布文章功能将授予 {username} 发布权限!",
    authorize: "授权",
    changeAuthorize: "改变授权",
    postTime: "发布时间(UTC)",
    wait: "待发布",
    posted: "已发布",
    confirmDelete: "确认删除吗?",
    toolsIntro: "工具箱",
    removeAuthorization_title: "移除授权",
    removeAuthorization_intro: "移除授权",
    removeAuthorization_remark: "移除授权",
    authorityManagement_title: "权限管理",
    authorityManagement_intro: "POST权限管理,删除授权",
    authorityManagement_remark: "POST权限管理,删除授权",
    removeAuthorizeTips: "您将移除 {username} 发布权限!",
    timeSpan: "时间跨度",
    autoClaimReward_title: "自动领取奖励",
    autoClaimReward_intro: "每5分钟,自动领取奖励",
    autoClaimReward_remark: "每5分钟,自动领取奖励",
    autoFollowClubVote_title: "跟随俱乐部帐号点赞",
    autoFollowClubVote_intro: "跟随俱乐部帐号点赞",
    autoFollowClubVote_remark: "跟随俱乐部帐号点赞",
    chartNum: "字符统计",
  },
  button: {
    bonusSBIRanking: "SBI奖金排行榜",
    voteNewActivity: "点击参与",
    voteJoin: "投票",
    close: "关闭",
    voteJoined: "已投票",
    claim: "领取",
    claimed: "已领取",
    checking: "检查中",
    rank: "榜单",
    login: "登录",
    flag: "标记",
    resteem: "转发",
    edit: "编辑",
    subscribe: "订阅",
    joined: "已加入",
    join: "加入",
    leave: "取消订阅",
    save: "保存",
    followed: "取消关注",
    follow: "关注",
    muted: "取消屏蔽",
    mute: "屏蔽",
    confirm: "确认",
    cancel: "取消",
    delete: "删除",
    delegate: "代理",
    exchange: "兑换",
    submit: "提交",
    register: "注册",
    authorize: "授权",
    enter: "进入",
    add: "添加",
    check: "检查",
  },
  input: {
    search: "搜索",
    exchangeuvfnumber: "请输入兑换的UVF数量",
    InputUserName: "请输入用户名",
  },
  table: {
    activityTitle: "活动标题",
    activityVoteNum: "投票数",
    estimatedBonus: "预估奖金",
    bonusType: "奖金类型",
    activityStatus: "状态",
    winnerName: "获奖人",
    accumulatedBonus: "累计奖金",
    sbiBonus: "sbi奖金",
    sbdBonus: "sbd奖金",
    uvfBonus: "uvf奖金",
    steemBonus: "steem奖金",
    author: "作者",
    title: "标题",
    planPostTime: "计划时间",
    postTime: "发送时间",
    postState: "发送状态",
    message: "信息",
    oper: "操作",
    userName: "用户名",
    permissionType: "权限类型",
  },
  error: {
    userOrPasswordError: "用户名或密码错误",
    systemError: "系统错误",
    noAirDrop: "对不起，您未获得空投！",
    airDropClaimed: "空投已领取",
    airDropEnd: "空投已结束",
    airDropNotBegin: "空投未开始",
    postError: "发文失败",
    uploadImageTypeError: "上传头像图片格式错误!",
    uploadImageSizeError: "上传头像图片大小不能超过 2MB!",
    toError: "请输入接收人",
    transferAmountError: "金额必须大于0",
    activeKeyError: "Active Key 错误",
    powerUpError: "Power Up 失败",
    powerDownError: "Power Down 失败",
    transError: "交易失败",
    balanceError: "余额错误",
    delegateError: "代理失败",
    exchangeNumError: "输入错误",
    operError: "操作异常",
    wrongPercentage: "百分比合计需要为100%",
    haveNullUser: "用户名不能为空",
    userNameExist: "用户名已存在",
    haveNullEmail: "Email 不能为空",
    emailExist: "Email已存在",
    userNameOrEmailIsReg: "用户名或邮箱已被注册",
    regVerifyError: "验证失败, 如有疑问请反馈至 Services@boylikegirl.club",
    regIn7day: "同一IP七天内注册过帐号",
    sapDatetimeNotNull: "定时发送时间不能为空",
    insufficientPoints: "积分不足",
  },
  success: {
    claimedSuccess: "领取成功",
    postSuccess: "发文成功",
    transSuccess: "交易成功",
    powerUpSuccess: "Power Up成功",
    powerDownSuccess: "Power Down成功",
    delegateSuccess: "代理成功",
    exchangeSuccess: "兑换成功",
    regVerifyEmailSend: "验证邮件已发送至邮箱请查收",
    regVerifySuccess: "注册信息验证成功,账户信息将发送至注册邮箱,请查收",
    authorizeSuccess: "授权成功",
    removeAuthorizeSuccess: "权限移除成功",
    joinSuccess: "加入成功",
  },
  desc: {
    opening: "进行中",
    closed: "已关闭",
    claimed: "已领取",
    waitClaimed: "待领取",
  },
  refresh: {
    pulling: "下拉刷新",
    loosing: "释放刷新",
    refresh: "正在刷新",
    success: "刷新完成",
  },
  loadmore: {
    loading: "正在加载",
    finished: "没有更多了",
    error: "请求失败，点击重新加载",
  },
  operType: {
    Post: "发文",
    Comment: "留言",
    Vote: "投票",
    DaliySign: "签到",
    ReadPost: "阅读文章",
    JoinLuckyNum: "参加幸运数字",
    SubscribeClub: "订阅俱乐部",
    WintessVote: "见证人投票",
    WitnessProxy: "见证人代理",
    Delegation: "代理",
    FirstLand: "首次登陆",
    ExchangeUVF: "兑换 UVF",
  },
};

export default zh;
