export default {
  name: "base-alert",
  props: {
    type: {
      type: String,
      "default": "default",
      description: "Alert type"
    },
    icon: {
      type: String,
      "default": "",
      description: "Alert icon. Will be overwritten by default slot"
    },
    dismissible: {
      type: Boolean,
      "default": false,
      description: "Whether alert is closes when clicking"
    }
  },
  data: function data() {
    return {
      visible: true
    };
  },
  methods: {
    dismissAlert: function dismissAlert() {
      this.visible = false;
    }
  }
};