import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  "class": "container-fluid mt-2"
};
var _hoisted_2 = {
  key: 0,
  "class": "container-fluid mt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_base_header = _resolveComponent("base-header");

  var _component_el_alert = _resolveComponent("el-alert");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_base_header, {
    type: "gradient-success",
    "class": "pb-4 pb-4 pt-5 pt-md-5"
  }), _createElementVNode("div", _hoisted_1, [!$data.isLoad ? (_openBlock(), _createElementBlock("div", _hoisted_2, [$data.isVerifyEmail ? (_openBlock(), _createBlock(_component_el_alert, {
    key: 0,
    title: _ctx.$t('success.regVerifySuccess'),
    type: "success",
    closable: false
  }, null, 8, ["title"])) : (_openBlock(), _createBlock(_component_el_alert, {
    key: 1,
    title: _ctx.$t('error.regVerifyError'),
    type: "error",
    closable: false
  }, null, 8, ["title"]))])) : _createCommentVNode("", true)])], 64);
}