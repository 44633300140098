import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.parse-float.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.array.filter.js";
import { getImgFromStr, getDateTimeStamp, getDateDiff } from "../../unit/public";
import ReSteem from "./ReSteem";
import VoteModel from "../VoteModel.vue";
import { extractLoginData } from "../../unit/UserUtil";
export default {
  props: {
    isShowComment: {
      type: Boolean,
      "default": true
    },
    blogs: {
      type: Object
    },
    permlink: {
      type: String,
      "default": ""
    }
  },
  components: {
    ReSteem: ReSteem,
    VoteModel: VoteModel
  },
  name: "post-foot-function",
  data: function data() {
    return {
      showModel: false,
      loginData: null,
      isOnUpvote: false,
      isShowVoters: false
    };
  },
  methods: {
    orderVoter: function orderVoter(voters) {
      return voters.sort(function (a, b) {
        return b.rshares - a.rshares;
      });
    },
    getVoterAmount: function getVoterAmount(rshares) {
      var totalRshare = this.blogs.active_votes.reduce(function (c, r) {
        return parseFloat(c) + parseFloat(r.rshares);
      }, 0);
      var percent = rshares * 1.0 / totalRshare;
      var payout = parseFloat(this.blogs.pending_payout_value) == 0 ? parseFloat(this.blogs.curator_payout_value) + parseFloat(this.blogs.author_payout_value) : parseFloat(this.blogs.pending_payout_value);
      var amount = payout * percent;
      return amount.toFixed(3);
    },
    dateDiff: function dateDiff(dateTime) {
      return getDateDiff(getDateTimeStamp(dateTime));
    },
    voteNewActivity: function voteNewActivity() {
      this.showModel = true;
      this.$refs.voteModel.openModel();
    },
    closeModel: function closeModel() {
      this.showModel = false;
    },
    votePost: function votePost() {
      this.isOnUpvote = true;
    },
    showVoters: function showVoters() {
      ///https://steemitimages.com/u/yuanyuanzi/avatar/small 用户头像.替换为用户名即可
      this.isShowVoters = true;
    }
  },
  computed: {
    isUpvote: function isUpvote() {
      var _this = this;

      return this.loginData && this.blogs.active_votes.filter(function (item) {
        return item.voter === _this.loginData[0] && parseFloat(item.rshares) >= 0;
      }).length >= 1;
    },
    isDownvote: function isDownvote() {
      var _this2 = this;

      return this.loginData && this.blogs.active_votes.filter(function (item) {
        return item.voter === _this2.loginData[0] && parseFloat(item.rshares) < 0;
      }).length >= 1;
    },
    upvoteNum: function upvoteNum() {
      return this.blogs.active_votes.filter(function (item) {
        return parseFloat(item.rshares) >= 0;
      }).length;
    },
    downvoteNum: function downvoteNum() {
      return this.blogs.active_votes.filter(function (item) {
        return parseFloat(item.rshares) < 0;
      }).length;
    },
    postLink: function postLink() {
      return "/" + this.blogs.parent_permlink + "/@" + this.blogs.author + "/" + this.blogs.permlink;
    }
  },
  watch: {},
  mounted: function mounted() {
    this.loginData = extractLoginData();
    /*   let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://static.like.co/sdk/v1/button.js";
    document.body.appendChild(script); */
  }
};