import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "container-fluid d-flex align-items-center"
};
var _hoisted_2 = {
  "class": "row"
};
var _hoisted_3 = {
  "class": "col-lg-10 col-md-12"
};
var _hoisted_4 = {
  "class": "display-2 text-white"
};
var _hoisted_5 = {
  "class": "text-white mt-0 mb-5"
};
var _hoisted_6 = {
  "class": "container-fluid mt--7"
};
var _hoisted_7 = {
  "class": "row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_base_header = _resolveComponent("base-header");

  var _component_lucky_num_air_drop = _resolveComponent("lucky-num-air-drop");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_base_header, {
    type: "gradient-success",
    "class": "pb-6 pb-8 pt-5 pt-md-8"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("label.helloClubFriend")), 1), _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("label.lucknumAirdropIntro")), 1)])])])];
    }),
    _: 1
  }), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.luckNumAirDropList, function (item) {
    return _openBlock(), _createBlock(_component_lucky_num_air_drop, {
      key: item.id,
      steemLuckNumAirDrop: item
    }, null, 8, ["steemLuckNumAirDrop"]);
  }), 128))])])]);
}