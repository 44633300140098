import { isLoggedIn, extractLoginData } from "./UserUtil";

/**
 *
 * @returns {boolean}
 */
export function hasCompatibleKeychain() {
  return (
    window.steem_keychain &&
    window.steem_keychain.requestSignBuffer &&
    window.steem_keychain.requestBroadcast &&
    window.steem_keychain.requestSignedCall
  );
}

/**
 *
 * @returns {boolean}
 */
export function isLoggedInWithKeychain() {
  if (!isLoggedIn()) {
    return false;
  }
  if (!hasCompatibleKeychain()) {
    // possible to log in w/ keychain, then disable plugin
    return false;
  }
  const data = localStorage.getItem("autopost2");
  const [login_with_keychain] = extractLoginData(data);
  return !!login_with_keychain;
}
