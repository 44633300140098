import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import LeftMenu from "../components/BaseComponents/LeftMenu.vue";
export default {
  components: {
    DashboardNavbar: DashboardNavbar,
    ContentFooter: ContentFooter,
    LeftMenu: LeftMenu
  },
  data: function data() {
    return {
      sidebarBackground: "vue" //vue|blue|orange|green|red|primary

    };
  },
  methods: {
    toggleSidebar: function toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};