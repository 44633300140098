import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_resolveDynamicComponent($props.tag), {
    "class": _normalizeClass(["badge", ["badge-".concat($props.type), $props.rounded ? "badge-pill" : '', $props.circle && 'badge-circle']])
  }, {
    "default": _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default", {}, function () {
        return [$props.icon ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          "class": _normalizeClass($props.icon)
        }, null, 2)) : _createCommentVNode("", true)];
      })];
    }),
    _: 3
  }, 8, ["class"]);
}