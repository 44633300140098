import _defineProperty from "/root/steemboylikegirlClub/gitproject/steemboylikegirlclub/steemboylikegirlclub/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "container-fluid"
};
var _hoisted_2 = {
  "class": "header-body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["header", _defineProperty({}, "bg-".concat($props.type), $props.type)])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")])])], 2);
}