import "core-js/modules/es.number.constructor.js";
export default {
  props: {
    target: {
      type: [String, Number],
      description: "Button target element"
    },
    toggled: {
      type: Boolean,
      "default": false,
      description: "Whether button is toggled"
    }
  }
};