import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = ["id", "disabled"];
var _hoisted_2 = ["for"];
var _hoisted_3 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["custom-control custom-checkbox", {
      disabled: $props.disabled,
      'form-check-inline': $props.inline
    }])
  }, [_withDirectives(_createElementVNode("input", {
    id: $data.cbId,
    "class": "custom-control-input",
    type: "checkbox",
    disabled: $props.disabled,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $options.model = $event;
    })
  }, null, 8, _hoisted_1), [[_vModelCheckbox, $options.model]]), _createElementVNode("label", {
    "for": $data.cbId,
    "class": "custom-control-label"
  }, [_renderSlot(_ctx.$slots, "default", {}, function () {
    return [$props.inline ? (_openBlock(), _createElementBlock("span", _hoisted_3, " ")) : _createCommentVNode("", true)];
  })], 8, _hoisted_2)], 2);
}