export default {
  name: "sidebar-item",
  props: {
    link: {
      type: Object,
      "default": function _default() {
        return {
          name: "",
          path: "",
          children: []
        };
      },
      description: "Sidebar link. Can contain name, path, icon and other attributes. See examples for more info"
    }
  },
  inject: {
    autoClose: {
      "default": true
    }
  },
  data: function data() {
    return {
      children: [],
      collapsed: true
    };
  },
  methods: {
    linkClick: function linkClick() {
      if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};