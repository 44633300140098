import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "container-fluid mt--7"
};
var _hoisted_2 = {
  "class": "row"
};
var _hoisted_3 = {
  "class": "col"
};
var _hoisted_4 = {
  "class": "row align-items-center"
};
var _hoisted_5 = {
  "class": "col"
};
var _hoisted_6 = {
  "class": "table-responsive"
};
var _hoisted_7 = {
  scope: "row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_base_header = _resolveComponent("base-header");

  var _component_base_table = _resolveComponent("base-table");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_base_header, {
    type: "gradient-success",
    "class": "pb-6 pb-8 pt-5 pt-md-8"
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    "class": _normalizeClass(["card shadow", $data.type === 'dark' ? 'bg-default' : ''])
  }, [_createElementVNode("div", {
    "class": _normalizeClass(["card-header border-0", $data.type === 'dark' ? 'bg-transparent' : ''])
  }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("h3", {
    "class": _normalizeClass(["mb-0", $data.type === 'dark' ? 'text-white' : ''])
  }, _toDisplayString(_ctx.$route.query.title), 3)])])], 2), _createElementVNode("div", _hoisted_6, [_createVNode(_component_base_table, {
    "class": _normalizeClass(["table align-items-center table-flush", $data.type === 'dark' ? 'table-dark' : '']),
    "thead-classes": $data.type === 'dark' ? 'thead-dark' : 'thead-light',
    "tbody-classes": "list",
    data: $data.tableData
  }, {
    columns: _withCtx(function () {
      return [_createElementVNode("th", null, _toDisplayString(_ctx.$t("table.winnerName")), 1), _createElementVNode("th", null, _toDisplayString(_ctx.$t("table.sbiBonus")), 1), _createElementVNode("th", null, _toDisplayString(_ctx.$t("table.activityStatus")), 1)];
    }),
    "default": _withCtx(function (row) {
      return [_createElementVNode("th", _hoisted_7, _toDisplayString(row.item.userName), 1), _createElementVNode("td", null, _toDisplayString(row.item.airDropPrize), 1), _createElementVNode("td", null, [_createElementVNode("span", {
        "class": _normalizeClass(["text-success mr-3", !row.item.isClaimed ? 'text-success' : 'text-danger'])
      }, _toDisplayString(row.item.isClaimed ? _ctx.$t("desc.claimed") : _ctx.$t("desc.waitClaimed")), 3)])];
    }),
    _: 1
  }, 8, ["class", "thead-classes", "data"])])], 2)])])])], 64);
}