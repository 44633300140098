import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "container-fluid d-flex align-items-center"
};
var _hoisted_2 = {
  "class": "row"
};
var _hoisted_3 = {
  "class": "col-lg-10 col-md-12"
};
var _hoisted_4 = {
  "class": "text-white mt-0 mb-5"
};
var _hoisted_5 = {
  "class": "container-fluid mt--7"
};
var _hoisted_6 = {
  "class": "row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_base_header = _resolveComponent("base-header");

  var _component_program_list = _resolveComponent("program-list");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_base_header, {
    type: "gradient-success",
    "class": "pb-6 pt-2 pt-md-7"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("label.programIntro")), 1)])])])];
    }),
    _: 1
  }), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.programList, function (item) {
    return _openBlock(), _createBlock(_component_program_list, {
      key: item.id,
      program: item
    }, null, 8, ["program"]);
  }), 128))])])]);
}