import _createForOfIteratorHelper from "/root/steemboylikegirlClub/gitproject/steemboylikegirlclub/steemboylikegirlclub/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import { createVNode as _createVNode } from "vue";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.parse-int.js";
import "core-js/modules/es.array.concat.js";
import { Remarkable } from "remarkable";
import { linkify } from "remarkable/linkify";
import HtmlReady from "../unit/HtmlReady";
import sanitize from "sanitize-html";
import sanitizeConfig, { noImageText } from "../unit/SanitizeConfig";
import YoutubePreview from "./YoutubePreview";
export default {
  name: "Markdown",
  props: {
    text: {
      type: String,
      "default": "",
      description: "body"
    },
    hideImages: {
      type: Boolean,
      "default": false
    },
    isProxifyImages: {
      type: Boolean,
      "default": false
    },
    large: {
      type: Boolean,
      "default": true
    },
    highQualityPost: {
      type: Boolean,
      "default": false
    },
    noImage: {
      type: Boolean,
      "default": false
    },
    allowNoImage: {
      type: Boolean,
      "default": false
    },
    allowDangerousHTML: {
      type: Boolean,
      "default": false
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    markdown: function markdown() {
      var renderer = new Remarkable({
        html: true,
        breaks: true,
        typographer: false
      });
      var text = this.text;
      var html = false; // See also ReplyEditor isHtmlTest

      var m = text.match(/^<html>([\S\s]*)<\/html>$/);

      if (m && m.length === 2) {
        html = true;
        text = m[1];
      } else {
        // See also ReplyEditor isHtmlTest
        html = /^<p>[\S\s]*<\/p>/.test(text);
      } // Strip out HTML comments. "JS-DOS" bug.


      text = text.replace(/<!--([\s\S]+?)(-->|$)/g, "(html comment removed: $1)");
      var renderedText = html ? text : renderer.render(text); // If content isn't wrapped with an html element at this point, add it.

      if (!renderedText.indexOf("<html>") !== 0) {
        renderedText = "<html>" + renderedText + "</html>";
      } // Embed videos, link mentions and hashtags, etc...


      if (renderedText) renderedText = HtmlReady(renderedText, {
        hideImages: this.hideImages,
        isProxifyImages: this.isProxifyImages
      }).html; // Complete removal of javascript and other dangerous tags..
      // The must remain as close as possible to dangerouslySetInnerHTML

      var cleanText = renderedText;

      if (this.allowDangerousHTML === true) {//
      } else {
        cleanText = sanitize(renderedText, sanitizeConfig({
          large: this.large,
          highQualityPost: this.highQualityPost,
          noImage: this.noImage && this.allowNoImage
        }));
      }

      if (/<\s*script/gi.test(cleanText)) {
        // Not meant to be complete checking, just a secondary trap and red flag (code can change)
        console.error("Refusing to render script tag in post text", cleanText);
        return _createVNode("div", null, null);
      }

      var noImageActive = cleanText.indexOf(noImageText) !== -1; // In addition to inserting the youtube component, this allows
      // react to compare separately preventing excessive re-rendering.

      var idx = 0;
      var sections = [];
      var replaceText = ""; // HtmlReady inserts ~~~ embed:${id} type ~~~

      var strList = cleanText.split("~~~ embed:");

      var _iterator = _createForOfIteratorHelper(strList),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var section = _step.value;
          var tempSection = "";
          var match = section.match(/^([A-Za-z0-9\?\=\_\-\/\.]+) (youtube|vimeo|twitch|dtube|threespeak)\s?(\d+)? ~~~/);

          if (match && match.length >= 3) {
            var id = match[1];
            var type = match[2];
            var startTime = match[3] ? parseInt(match[3]) : 0;
            var w = this.large ? 640 : 480,
                h = this.large ? 360 : 270;

            if (type === "youtube") {
              var url = "https://www.youtube.com/embed/".concat(id, "?autoplay=1&autohide=1&enablejsapi=0&rel=0&origin=https://boylikegirl.club&start=").concat(startTime);
              tempSection = "<div  className='videoWrapper'>" + "<iframe" + " src='".concat(url, "'") + " width='".concat(w, "'") + " height='".concat(h, "'") + " frameBorder='0'" + " allowFullScreen='true'" + "></iframe> " + " </div> ";
            } else if (type === "threespeak") {
              var _url = "https://3speak.online/embed?v=".concat(id);

              tempSection = " <div className='videoWrapper' key=".concat(id, ">") + " <iframe" + "    src={url}" + "   width={w}" + "    height={h}" + "  frameBorder='0'" + " webkitallowfullscreen='true'" + " mozallowfullscreen='true'" + " allowFullScreen" + " title='ThreeSpeak video ".concat(id) + "></iframe> " + " </div> ";
            } else if (type === "vimeo") {
              var _url2 = "https://player.vimeo.com/video/".concat(id, "#t=").concat(startTime, "s");

              tempSection = " <div className='videoWrapper' key=".concat(id, ">") + " <iframe" + "    src={url}" + "   width={w}" + "    height={h}" + "  frameBorder='0'" + " webkitallowfullscreen='true'" + " mozallowfullscreen='true'" + " allowFullScreen" + "   title='Vimeo video ".concat(id, "'") + "></iframe> " + " </div> ";
            } else if (type === "twitch") {
              var _url3 = "https://player.twitch.tv/".concat(id);

              tempSection = " <div className='videoWrapper' key=".concat(id, ">") + " <iframe" + "    src={url}" + "   width={w}" + "    height={h}" + "  frameBorder='0'" + " webkitallowfullscreen='true'" + " mozallowfullscreen='true'" + " allowFullScreen" + "    title='Twitch video ".concat(id, "'") + "></iframe> " + " </div> ";
            } else if (type === "dtube") {
              var _url4 = "https://emb.d.tube/#!/".concat(id);

              tempSection = " <div className='videoWrapper' key=".concat(id, ">") + " <iframe" + "    src={url}" + "   width={w}" + "    height={h}" + "  frameBorder='0'" + " webkitallowfullscreen='true'" + " mozallowfullscreen='true'" + " allowFullScreen" + "   title='DTube video ".concat(id, "'") + "></iframe> " + " </div> ";
            } else {
              console.error("MarkdownViewer unknown embed type", type);
            }

            if (match[3]) {
              section = section.substring("".concat(id, " ").concat(type, " ").concat(startTime, " ~~~").length);
            } else {
              section = section.substring("".concat(id, " ").concat(type, " ~~~").length);
            }

            if (section === "") continue;
          }

          replaceText += tempSection + section;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return replaceText;
      /*   let body = this.body;
      body = remarkable.render(body);
           body = HtmlReady(body, {
        hideImages: false,
        isProxifyImages: true,
      }).html;
           // body = sanitizeHtml(body); 
      return body; */
    }
  }
};