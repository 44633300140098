import dsteem from "./DsteemUtil";
import {
  claimLuckyNumAirDrop,
  exchangeUVF,
  sapEdit as se,
  sapDelete as sd,
  joinAutoClaimReward as jacr,
  joinAutoFollowClubVote as jafcv,
} from "../apis/services/steemActivity";
import { extractLoginData } from "./UserUtil";
/**通过用户名密码登录 */
export const claimWithUserNameAndPassword = async function (
  username,
  password,
  steemLuckNumAirDropId,
  callback
) {
  var i = {
    id: "claimLuckNumAirDrop",
    json:
      '{"type": "claimLuckNumAirDrop", "data": {"userName": "' +
      username +
      '","steemLuckNumAirDropId":' +
      steemLuckNumAirDropId +
      ',"app": "boylikegirl.club/0.1"}}',
    required_auths: [],
    required_posting_auths: [username],
  };

  let operations = [["custom_json", i]];

  let expireTime = 60 * 1000; //1 min
  let prop = await dsteem.getDynamicGlobalProperties();

  let op = {
    ref_block_num: prop.head_block_number, //reference head block number required by tapos (transaction as proof of stake)
    ref_block_prefix: Buffer.from(prop.head_block_id, "hex").readUInt32LE(4), //reference buffer of block id as prefix
    expiration: new Date(Date.now() + expireTime).toISOString().slice(0, -5), //set expiration time for transaction (+1 min)
    operations: operations, //example of operation object for vote

    extensions: [], //extensions for this transaction
  };
  let stx = dsteem.signTx(op, password);
  let blockMessage = await dsteem.sendOperations(operations, password);
  /*   let data = {
    data: Buffer.from(JSON.stringify(stx)).toString("base64"),
    blockMessage: JSON.stringify(blockMessage),
  }; */
  stx.id = blockMessage.id;
  stx.block_num = blockMessage.block_num;
  stx.trx_num = blockMessage.trx_num;
  stx.expired = blockMessage.expired;
  let data = {
    data: JSON.stringify(stx),
  };
  await claimLuckyNumAirDrop(data).then(
    function (res) {
      callback(res);
    },
    function (res) {
      callback(res);
    }
  );
};
/**通过SteemKeychain登录 */
export const claimWithSteemKeychain = function (
  username,
  steemLuckNumAirDropId,
  callback
) {
  window.steem_keychain.requestCustomJson(
    username,
    "claimLuckNumAirDrop",
    "Posting",
    '{"type": "claimLuckNumAirDrop", "data": {"userName": "' +
      username +
      '","steemLuckNumAirDropId": ' +
      steemLuckNumAirDropId +
      ',"app": "boylikegirl.club/0.1"}}',
    "claimLuckNumAirDrop",
    function (response) {
      if (response.result) {
        /*   let data = {
          data: Buffer.from(JSON.stringify(response.result)).toString("base64"),
        }; */

        let data = {
          data: JSON.stringify(response.result),

          //blockMessage: JSON.stringify(blockMessage),
        };

        claimLuckyNumAirDrop(data).then(
          function (res) {
            callback(res);
          },
          function (res) {
            callback(res);
          }
        );
      }
    }
  );
};

/**通过用户名密码登录 */
export const exchangeUVFWithUserNameAndPassword = async function (
  username,
  password,
  uvfNum,
  marketId,
  callback
) {
  var i = {
    id: "exchangeUVF",
    json:
      '{"type": "exchangeUVF", "data": {"userName": "' +
      username +
      '","uvfNum":' +
      uvfNum +
      ',"marketId":' +
      marketId +
      ',"app": "boylikegirl.club/0.1"}}',
    required_auths: [],
    required_posting_auths: [username],
  };

  let operations = [["custom_json", i]];

  let expireTime = 60 * 1000; //1 min
  let prop = await dsteem.getDynamicGlobalProperties();

  let op = {
    ref_block_num: prop.head_block_number, //reference head block number required by tapos (transaction as proof of stake)
    ref_block_prefix: Buffer.from(prop.head_block_id, "hex").readUInt32LE(4), //reference buffer of block id as prefix
    expiration: new Date(Date.now() + expireTime).toISOString().slice(0, -5), //set expiration time for transaction (+1 min)
    operations: operations, //example of operation object for vote

    extensions: [], //extensions for this transaction
  };
  let stx = dsteem.signTx(op, password);
  let blockMessage = await dsteem.sendOperations(operations, password);
  /*   let data = {
    data: Buffer.from(JSON.stringify(stx)).toString("base64"),
    blockMessage: JSON.stringify(blockMessage),
  }; */
  stx.id = blockMessage.id;
  stx.block_num = blockMessage.block_num;
  stx.trx_num = blockMessage.trx_num;
  stx.expired = blockMessage.expired;
  let data = {
    data: JSON.stringify(stx),
  };

  await exchangeUVF(data).then(
    function (res) {
      callback(res);
    },
    function (res) {
      callback(res);
    }
  );
};
/**通过SteemKeychain登录 */
export const exchangeUVFWithSteemKeychain = function (
  username,
  uvfNum,
  marketId,
  callback
) {
  window.steem_keychain.requestCustomJson(
    username,
    "exchangeUVF",
    "Posting",
    '{"type": "exchangeUVF", "data": {"userName": "' +
      username +
      '","uvfNum": ' +
      uvfNum +
      ',"marketId":' +
      marketId +
      ',"app": "boylikegirl.club/0.1"}}',
    "exchangeUVF",
    function (response) {
      if (response.result) {
        /*   let data = {
          data: Buffer.from(JSON.stringify(response.result)).toString("base64"),
        }; */

        let data = {
          data: JSON.stringify(response.result),

          //blockMessage: JSON.stringify(blockMessage),
        };

        exchangeUVF(data).then(
          function (res) {
            callback(res);
          },
          function (res) {
            callback(res);
          }
        );
      }
    }
  );
};
/** 编辑SAP信息 */
export const sapEdit = async function (input) {
  let loginData = extractLoginData();
  let json = {};
  json.type = "sap";
  json.data = {};
  json.data.userName = input.author;
  json.data.sap = JSON.stringify(input);
  json.data.app = "boylikegirl.club/0.1";
  let i = {
    id: "sap",
    json: JSON.stringify(json),
    /* '{"type": "sap", "data": {"userName": "' +
    input.author + '","sapDateTime":"' +
    input.sapDateTime +
    '","sap":"' +
    JSON.stringify(input) +
    '","app": "boylikegirl.club/0.1"}}', */
    required_auths: [],
    required_posting_auths: [input.author],
  };

  let operations = [["custom_json", i]];

  let expireTime = 60 * 1000; //1 min
  let prop = await dsteem.getDynamicGlobalProperties();

  let op = {
    ref_block_num: prop.head_block_number, //reference head block number required by tapos (transaction as proof of stake)
    ref_block_prefix: Buffer.from(prop.head_block_id, "hex").readUInt32LE(4), //reference buffer of block id as prefix
    expiration: new Date(Date.now() + expireTime).toISOString().slice(0, -5), //set expiration time for transaction (+1 min)
    operations: operations, //example of operation object for vote

    extensions: [], //extensions for this transaction
  };

  let stx = dsteem.signTx(op, loginData[1]);

  let blockMessage = await dsteem.sendOperations(operations);

  /*   let data = {
    data: Buffer.from(JSON.stringify(stx)).toString("base64"),
    blockMessage: JSON.stringify(blockMessage),
  }; */
  stx.id = blockMessage.id;
  stx.block_num = blockMessage.block_num;
  stx.trx_num = blockMessage.trx_num;
  stx.expired = blockMessage.expired;
  let data = {
    data: JSON.stringify(stx),
  };

  return { code: 0 }; //await se(data);
};
/** 编辑SAP信息 */
export const sapDelete = async function (input) {
  let loginData = extractLoginData();
  let json = {};
  json.type = "sapdel";
  json.data = {};
  json.data.userName = input.author;
  json.data.id = input.id;
  json.data.app = "boylikegirl.club/0.1";
  let i = {
    id: "sapdel",
    json: JSON.stringify(json),
    /* '{"type": "sap", "data": {"userName": "' +
    input.author + '","sapDateTime":"' +
    input.sapDateTime +
    '","sap":"' +
    JSON.stringify(input) +
    '","app": "boylikegirl.club/0.1"}}', */
    required_auths: [],
    required_posting_auths: [input.author],
  };

  let operations = [["custom_json", i]];

  let expireTime = 60 * 1000; //1 min
  let prop = await dsteem.getDynamicGlobalProperties();

  let op = {
    ref_block_num: prop.head_block_number, //reference head block number required by tapos (transaction as proof of stake)
    ref_block_prefix: Buffer.from(prop.head_block_id, "hex").readUInt32LE(4), //reference buffer of block id as prefix
    expiration: new Date(Date.now() + expireTime).toISOString().slice(0, -5), //set expiration time for transaction (+1 min)
    operations: operations, //example of operation object for vote

    extensions: [], //extensions for this transaction
  };

  let stx = dsteem.signTx(op, loginData[1]);

  let blockMessage = await dsteem.sendOperations(operations);

  /*   let data = {
    data: Buffer.from(JSON.stringify(stx)).toString("base64"),
    blockMessage: JSON.stringify(blockMessage),
  }; */
  stx.id = blockMessage.id;
  stx.block_num = blockMessage.block_num;
  stx.trx_num = blockMessage.trx_num;
  stx.expired = blockMessage.expired;
  let data = {
    data: JSON.stringify(stx),
  };

  return { code: 0 }; //await sd(data);
};
/** 编辑SAP信息 */
export const joinAutoClaimRewards = async function (userName) {
  return await jacr(
    await commonSendOperations(userName, "joinAutoClaimRewards", "tools")
  );
};

/** 编辑SAP信息 */
export const joinAutoFollowClubVote = async function (userName) {
  return await jafcv(
    await commonSendOperations(userName, "joinAutoFollowClubVote", "tools")
  );
};
/** 编辑SAP信息 */
const commonSendOperations = async function (userName, id, type) {
  let loginData = extractLoginData();
  let json = {};
  json.type = type;
  json.data = {};
  json.data.userName = userName;
  json.data.id = 0;
  json.data.app = "boylikegirl.club/0.1";
  let i = {
    id: id,
    json: JSON.stringify(json),
    /* '{"type": "sap", "data": {"userName": "' +
    input.author + '","sapDateTime":"' +
    input.sapDateTime +
    '","sap":"' +
    JSON.stringify(input) +
    '","app": "boylikegirl.club/0.1"}}', */
    required_auths: [],
    required_posting_auths: [userName],
  };

  let operations = [["custom_json", i]];

  let expireTime = 60 * 1000; //1 min
  let prop = await dsteem.getDynamicGlobalProperties();

  let op = {
    ref_block_num: prop.head_block_number, //reference head block number required by tapos (transaction as proof of stake)
    ref_block_prefix: Buffer.from(prop.head_block_id, "hex").readUInt32LE(4), //reference buffer of block id as prefix
    expiration: new Date(Date.now() + expireTime).toISOString().slice(0, -5), //set expiration time for transaction (+1 min)
    operations: operations, //example of operation object for vote

    extensions: [], //extensions for this transaction
  };

  let stx = dsteem.signTx(op, loginData[1]);

  let blockMessage = await dsteem.sendOperations(operations);

  /*   let data = {
    data: Buffer.from(JSON.stringify(stx)).toString("base64"),
    blockMessage: JSON.stringify(blockMessage),
  }; */
  stx.id = blockMessage.id;
  stx.block_num = blockMessage.block_num;
  stx.trx_num = blockMessage.trx_num;
  stx.expired = blockMessage.expired;
  let data = {
    data: JSON.stringify(stx),
  };

  return data;
};

export default {
  claimWithUserNameAndPassword,
  claimWithSteemKeychain,
  exchangeUVFWithUserNameAndPassword,
  exchangeUVFWithSteemKeychain,
  sapEdit,
  sapDelete,
  joinAutoClaimRewards,
  joinAutoFollowClubVote,
};
