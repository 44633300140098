import "core-js/modules/es.array.fill.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "nav-wrapper"
};
var _hoisted_2 = ["href", "onClick", "aria-selected"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_tab_item_content = _resolveComponent("tab-item-content");

  return _openBlock(), _createBlock(_resolveDynamicComponent($options.layoutComponent), null, {
    nav: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("ul", {
        "class": _normalizeClass(["nav", [$props.type ? "nav-pills-".concat($props.type) : '', $props.pills ? 'nav-pills' : 'nav-tabs', {
          'nav-pills-icons': $props.icons
        }, {
          'nav-fill': $props.fill
        }, {
          'nav-pills-circle': $props.circle
        }, {
          'justify-content-center': $props.centered
        }, $props.tabNavClasses]]),
        role: "tablist"
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.tabs, function (tab) {
        return _openBlock(), _createElementBlock("li", {
          "class": "nav-item",
          key: tab.id || tab.title
        }, [_createElementVNode("a", {
          "data-toggle": "tab",
          role: "tab",
          "class": _normalizeClass(["nav-link", {
            active: tab.active
          }]),
          href: "#".concat(tab.id || tab.title),
          onClick: _withModifiers(function ($event) {
            return $options.activateTab(tab);
          }, ["prevent"]),
          "aria-selected": tab.active
        }, [tab.icon ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          "class": _normalizeClass(tab.icon)
        }, null, 2)) : _createCommentVNode("", true), _createVNode(_component_tab_item_content, {
          tab: tab
        }, null, 8, ["tab"])], 10, _hoisted_2)]);
      }), 128))], 2)])];
    }),
    content: _withCtx(function () {
      return [_createElementVNode("div", {
        "class": _normalizeClass(["tab-content", [$props.tabContentClasses]])
      }, [_renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps($options.slotData)))], 2)];
    }),
    _: 3
  });
}