export default {
  name: "tab-pane",
  props: ["label", "id", "title", "icon"],
  inject: ["addTab", "removeTab"],
  data: function data() {
    return {
      active: false
    };
  },
  mounted: function mounted() {
    this.addTab(this);
  },
  unmounted: function unmounted() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }

    this.removeTab(this);
  }
};