/*!

=========================================================
* Vue Argon Dashboard - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import i18n from "./plugins/i18n";
import mitt from "mitt";

import "element-plus/dist/index.css";
import Vuejsloadmore from "./plugins/vuejs-loadmore";
import store from "./store";
import mavonEditor from "mavon-editor-v3-boylikegirl";
import "mavon-editor-v3-boylikegirl/dist/css/index.css";
import { heartBeat } from "./apis/services/steemActivity";
import VueWechatTitle from "vue-wechat-title";
// use

const appInstance = createApp(App);

function initRpcNode() {
  let rpcNode = process.env.VUE_APP_STEEM_RPC.split("|")[0];
  if (!localStorage.getItem("rpcNode"))
    localStorage.setItem("rpcNode", rpcNode);
}
initRpcNode();
//全局定时器
window.setInterval(() => {
  setTimeout(() => {
    heartBeat();
  }, 0);
}, 2 * 60 * 1000);

//首先安装第三方库mitt
//npm install --save mitt
//挂载事务总线
appInstance.config.globalProperties.$bus = new mitt();
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(i18n);
appInstance.use(store);
appInstance.use(Vuejsloadmore);
appInstance.use(mavonEditor);

appInstance.use(VueWechatTitle);
appInstance.mount("#app");
