import HtmlReady from "./HtmlReady";
import { Remarkable } from "remarkable";
import { $t } from "../plugins/i18n";
import { proxifyImageUrl } from "./ProxifyUrl";
const remarkable = new Remarkable({
  html: true,
  linkify: false,
});

const getValidImage = (array) => {
  return array &&
    Array.isArray(array) &&
    array.length >= 1 &&
    typeof array[0] === "string"
    ? array[0]
    : null;
};
const proxify = (url, size) => proxifyImageUrl(url, size).replace(/ /g, "%20");
/**
 * 获取用户信息
 *
 */
export const getImgFromStr = function (json_metadata, body) {
  let json = json_metadata || {};
  let image_link;

  // If nothing found in json metadata, parse body and check images/links
  if (!image_link) {
    let rtags;
    {
      const isHtml = /^<html>([\S\s]*)<\/html>$/.test(body);
      const htmlText = isHtml
        ? body
        : remarkable.render(
            body
              ? body.replace(
                  /<!--([\s\S]+?)(-->|$)/g,
                  "(html comment removed: $1)"
                )
              : null
          );

      rtags = HtmlReady(htmlText, {
        mutate: false,
      });
    }

    [image_link] = Array.from(rtags.images);
  }
  if (!image_link) {
    try {
      image_link = json && json.image ? getValidImage(json.image) : null;
    } catch (e) {
      //console.log(e);
    }
  }

  const blogImg = image_link ? proxify(image_link, "640x480") : "";
  return blogImg;
};
/**
 * 日期转换为时间错
 * @param {*} 日期
 * @returns
 */
export const getDateTimeStamp = function (dateStr) {
  return Date.parse(dateStr.replace(/-/gi, "/").replace("T", " "));
};
/**
 * 时间搓转换为日期描述
 * @param {*} 时间戳
 * @returns
 */
export const getDateDiff = function (dateTimeStamp) {
  var minute = 1000 * 60;
  var hour = minute * 60;
  var day = hour * 24;
  //var halfamonth = day * 15;
  var month = day * 30;
  var now = new Date();
  var tempTime =
    now.getUTCFullYear() +
    "/" +
    (now.getUTCMonth() + 1) +
    "/" +
    now.getUTCDate() +
    " " +
    now.getUTCHours() +
    ":" +
    now.getUTCMinutes() +
    ":" +
    now.getUTCSeconds() +
    "." +
    now.getUTCMilliseconds();
  now = Date.parse(tempTime);

  var diffValue = now - dateTimeStamp;
  var result = "";

  var monthC = diffValue / month;
  var weekC = diffValue / (7 * day);
  var dayC = diffValue / day;
  var hourC = diffValue / hour;
  var minC = diffValue / minute;
  if (monthC >= 1) {
    result = "" + parseInt(monthC) + " " + $t("label.monthsago");
  } else if (weekC >= 1) {
    result = "" + parseInt(weekC) + " " + $t("label.weeksago");
  } else if (dayC >= 1) {
    result = "" + parseInt(dayC) + " " + $t("label.daysago");
  } else if (hourC >= 1) {
    result = "" + parseInt(hourC) + " " + $t("label.hoursago");
  } else if (minC >= 1) {
    result = "" + parseInt(minC) + " " + $t("label.minutesago");
  } else result = $t("label.justnow");
  return result;
};
/**
 * 时间搓转换为日期描述
 * @param {*} 时间戳
 * @returns
 */
export const getPowerDownDateDiff = function (dateTimeStamp) {
  var minute = 1000 * 60;
  var hour = minute * 60;
  var day = hour * 24;
  //var halfamonth = day * 15;
  var month = day * 30;
  var now = new Date();
  var tempTime =
    now.getUTCFullYear() +
    "/" +
    (now.getUTCMonth() + 1) +
    "/" +
    now.getUTCDate() +
    " " +
    now.getUTCHours() +
    ":" +
    now.getUTCMinutes() +
    ":" +
    now.getUTCSeconds() +
    "." +
    now.getUTCMilliseconds();
  now = Date.parse(tempTime);

  var diffValue = now - dateTimeStamp;
  var result = "";
  if (diffValue < 0) {
    var afterDayC = -diffValue / day;
    var afterHourC = -diffValue / hour;
    var afterMinC = -diffValue / minute;

    if (afterDayC >= 1) {
      result = $t("label.powerDownDaysAfter", {
        time: parseInt(afterDayC + 1),
      });
    } else if (afterHourC >= 1) {
      result = $t("label.powerDownHoursAfter", {
        time: parseInt(afterHourC) + 1,
      });
    } else if (afterMinC >= 1) {
      result = $t("label.powerDownMinutesAfter", {
        time: parseInt(afterMinC + 1),
      });
    }
    return result;
  }
};
/**强制补位 */
export const toDecimal = function (x, num) {
  let f = parseFloat(x * 1000);
  if (isNaN(f)) {
    return false;
  }
  f = Math.round(x * 1000) / 1000;

  let s = f.toString();
  let rs = s.indexOf(".");
  if (rs < 0) {
    rs = s.length;
    s += ".";
  }
  while (s.length <= rs + num) {
    s += "0";
  }
  return s;
};
/***时间戳转换为时间 */
export const timestampToTime = function (timestamp) {
  var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  var h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
};

/***时间增加天数 */
export const dateAddDay = function (dateStr, days) {
  var dateTime = new Date(dateStr);
  dateTime = dateTime.setDate(dateTime.getDate() + parseInt(days));

  dateTime = new Date(dateTime);
  return dateTime;
};

/***时间增加月份 */
export const dateAddMonth = function (dateStr, months) {
  var dateTime = new Date(dateStr);
  dateTime = dateTime.setMonth(dateTime.getMonth() + parseInt(months));
  dateTime = new Date(dateTime);
  return dateTime;
};

/***时间增加年份 */
export const dateAddYear = function (dateStr, years) {
  var dateTime = new Date(dateStr);
  dateTime = dateTime.setYear(dateTime.getYear() + parseInt(years));
  dateTime = new Date(dateTime);
  return dateTime;
};

export const formatDate = function (date, pattern) {
  //如果不设置，默认为当前时间
  if (!date) date = new Date();
  if (typeof date === "string") {
    if (date == "") date = new Date();
    else date = new Date(date.replace(/-/g, "/"));
  }
  /*补00*/
  var toFixedWidth = function (value) {
    var result = 100 + value;
    return result.toString().substring(1);
  };

  /*配置*/
  var options = {
    regeExp: /(yyyy|M+|d+|h+|m+|s+|ee+|ws?|p)/g,
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    weeks: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
  };

  /*时间切换*/
  var swithHours = function (hours) {
    return hours < 12 ? "AM" : "PM";
  };

  /*配置值*/
  var pattrnValue = {
    yyyy: date.getFullYear(), //年份
    MM: toFixedWidth(date.getMonth() + 1), //月份
    dd: toFixedWidth(date.getDate()), //日期
    hh: toFixedWidth(date.getHours()), //小时
    mm: toFixedWidth(date.getMinutes()), //分钟
    ss: toFixedWidth(date.getSeconds()), //秒
    ee: options.months[date.getMonth()], //月份名称
    ws: options.weeks[date.getDay()], //星期名称
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
    p: swithHours(date.getHours()),
  };

  return pattern.replace(options.regeExp, function () {
    return pattrnValue[arguments[0]];
  });
};

// 字符统计
export const countChars = function (str) {
  return str.length;
};

// 字数统计
export const countChineseChars = function (str) {
  let regex = /[\u4E00-\u9FA5]/g;
  let result = str.match(regex);
  return result ? result.length : 0;
};

export default {
  getImgFromStr,
  getDateDiff,
  getDateTimeStamp,
  toDecimal,
  getPowerDownDateDiff,
  timestampToTime,
  dateAddDay,
  dateAddMonth,
  dateAddYear,
  formatDate,
  countChars,
  countChineseChars,
};
