import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";

/* import NavbarToggleButton from "./NavbarToggleButton"; */
export default {
  name: "base-nav",
  components: {
    /*  NavbarToggleButton, */
  },
  props: {
    type: {
      type: String,
      "default": "",
      description: "Navbar type (e.g default, primary etc)"
    },
    title: {
      type: String,
      "default": "",
      description: "Title of navbar"
    },
    contentId: {
      type: [String, Number],
      "default": Math.random().toString(),
      description: "Explicit id for the menu. By default it's a generated random number"
    },
    containerClasses: {
      type: [String, Object, Array],
      "default": "container-fluid"
    },
    transparent: {
      type: Boolean,
      "default": false,
      description: "Whether navbar is transparent"
    },
    expand: {
      type: Boolean,
      "default": false,
      description: "Whether navbar should contain `navbar-expand-lg` class"
    },
    showToggleButton: {
      type: Boolean,
      "default": true
    }
  },
  data: function data() {
    return {
      toggled: false
    };
  },
  methods: {
    closeMenu: function closeMenu() {
      this.toggled = false;
    }
  }
};