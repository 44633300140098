import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.date.now.js";
import Markdown from "../../Markdown";
import { getDateTimeStamp, getDateDiff } from "../../../unit/public";
export default {
  name: "CommentItem",
  components: {
    Markdown: Markdown
  },
  props: {
    comment: {
      type: Object,
      "default": function _default() {},
      required: true
    },
    id: {
      type: [String, Number],
      required: true
    },
    parent: {
      type: Object,
      "default": function _default() {}
    },
    user: {
      type: Object,
      "default": function _default() {}
    }
  },
  computed: {
    isSubComment: function isSubComment() {
      return this.id.split("-").length === 3;
    }
  },
  methods: {
    dateDiff: function dateDiff(dateTime) {
      dateTime = dateTime.replace("T", " ");
      return getDateDiff(getDateTimeStamp(dateTime));
    },
    formatTime: function formatTime(time) {
      var local = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var d = new Date(time);

      if (local) {
        return d.toString();
      }

      var now = Date.now();
      var diff = (now - d) / 1000;

      switch (true) {
        case diff < 30:
          return "刚刚";

        case diff < 3600:
          return Math.ceil(diff / 60) + "分钟前";

        case diff < 3600 * 24:
          return Math.ceil(diff / 3600) + "小时前";

        case diff < 3600 * 24 * 30:
          return Math.floor(diff / 3600 / 24) + "天前";

        case diff < 3600 * 24 * 365:
          return Math.floor(diff / 3600 / 24 / 30) + "月前";

        default:
          return Math.floor(diff / 3600 / 24 / 365) + "年前";
      }
    }
  }
};