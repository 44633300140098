const en = {
  login: {
    login: "Login",
    cancel: "Cancel",
    loginout: "Logout",
  },
  menu: {
    lucknum: "Luck Number",
    airdrop: "Airdrop",
    lucknumairdroprank: "Lucky Number Airdrop Rank",
    blog: "Blog",
    communities: "Community",
    friends: "Friend",
    friends1: "Friend",
    myCommunities: "MyCommunities",
    myCommunities1: "MyCommunities",
    post: "Post",
    home: "Blog",
    article: "Article",
    community: "Community",
    article1: "Article",
    communityIndex: "Community",
    post1: "Post",
    friend: "Friend",
    friend1: "Friend",
    pointsmarket: "Points Market",
    setting: "Setting",
    wallet: "Wallet",
    clubentrance: "Club Entrance",
    marketuvfexchange: "UVF Exchange",
    post2: "Edit",
    reg: "Register",
    verifyemail: "Registration Verification",
    program: "Programs",
    sap: "My Sap Record",
    sappost: "Sap Post",
    tools: "Tools",
    removeAuthorization: "Remove Authorization",
    authorityManagement: "Authority Management",
  },
  label: {
    thirtyDayPeolpeNum: "30 Day People Number",
    totalPeolpeNum: "Total People Number",
    totalBonus: "Total Bonus(SBD)",
    totalSbiBonus: "Total Bonus(SBI)",
    totalUvfBonus: "Total Bonus(UVF)",
    totalSteemBonus: "Total Bonus(Steem)",
    currentWeekPeopleNum: "Current Week People Number",
    lastWeekPeopleNum: "Last Week People Number",
    currentWeekBonus: "Current Week Bonus",
    lastWeekBonus: "Last Week Bonus",
    activityList: "Activity List",
    bonusRanking: "Bonus Ranking",
    postKey: "PostKey",
    UserLogin: "User Login",
    activeKey: "ActiveKey",
    comfirmTrans: "Comfirm Trans",
    SignInWith: "Sign In With", //Sign in with
    OrSignInWithCredentials: "Or sign in with credentials", //Or sign in with credentials
    InputUserName: "User Name", //Input UserName
    InputPassWord: "PostKey", //Input PostKey
    InputActivePassWord: "ActiveKey", //Input ActiveKey
    SignIn: "Sign In",
    RememberMe: "Remember Me",
    UseSteemKeychain: "Use SteemKeychain Sign In",
    sbiBalance: "SBI Balance",
    balance: "Balance",
    pleaseLogin: "Please Login",
    myAirDrop: "My Airdrop",
    language: "language",
    node: "rpc Node",
    creatTime: "",
    monthsago: "months ago",
    hoursago: "hours ago",
    minutesago: "minutes ago",
    daysago: "days ago",
    weeksago: "weeks ago",
    justnow: "just now",
    powerDownDaysAfter:
      "The next power down is scheduled to happen in {time} days",
    powerDownHoursAfter:
      "The next power down is scheduled to happen in {time} hours",
    powerDownMinutesAfter:
      "The next power down is scheduled to happen in {time} minutes",
    publish: "post",
    postIntro: "Markdown",
    addTag: "New Tag",
    postTitle: "Title",
    postBody: "Body",
    postTag: "Tag",
    reply: "Reply",
    delete: "Delete",
    author: "Author",
    emoj: "Emoj",
    image: "Image",
    allPost: "All Post",
    communites: "Communites",
    communityTitle: "Title",
    communityAbout: "About",
    communityDescription: "Description",
    communityRule: "Rule",
    subscribers: "Subscribers",
    posters: "Posters",
    posts: "Posts",
    helloClubFriend: "Hello club friends",
    lucknumAirdropIntro:
      "This is the place to receive the airdrops for the Boylikegirl Club Lucky Number Event. I wish you a happy supply. Airdrops are not often available, but there will be from time to time",
    deadline: "Deadline",
    profileImage: "Profile image",
    displayName: "Display Name",
    about: "About",
    location: "Location",
    website: "Website",
    likeid: "Like Id",
    communityAvatarUrl: "AvatarUrl",
    transfer: "Transfer",
    cancel: "Cancel",
    transferSend: "Send",
    transferRevice: "Revice",
    transferTo: "To",
    transferFrom: "From",
    date: "Date",
    title: "Title",
    memo: "Memo",
    points: "Points",
    registerLiker: "Register Liker",
    SteemWallet: "Steem Wallet",
    PointsWallet: "Points Wallet",
    uploadImageProgress: "Image upload progress",
    from: "from",
    to: "to",
    amount: "Amount",
    comfirmPowerUp: "Convert to STEEM POWER",
    comfirmPowerUpContent:
      "Influence tokens which give you more control over post payouts and allow you to earn on curation rewards.<br /> STEEM POWER is non-transferable and requires 1 month (4 payments) to convert back to Steem.",
    comfirmPowerDown: "Power Down",
    comfirmPowerDownContent: "",
    blog: "Blog",
    post: "Posts",
    Delegations: "Delegations",
    incoming: "Incoming",
    outgoing: "Outgoing",
    expiring: "Expiring",
    Delegator: "Delegator",
    Delegatee: "Delegatee",
    filter: "Filter",
    availableSP: "Available SP",
    delegate: "Delegate",
    currentReward: "Your current rewards",
    pointsMarketIntro:
      "Points Market, which allows users use Points in more ways and possibilities",
    uvf_title: "Upvote fund",
    uvf_intro:
      "The Likes Fund is a fund where the sponsor and the sponsored are united and when the sponsor transfers Steem to uvf, both the sponsor and the sponsored will receive 20 times upvote  each day.<br />Sponsorship amount can be accumulated",
    marketuvfexchange: "UVF Exchange",
    pointExhangeUvfRatio: "Ratio",
    signature: "Signature",
    blogPostRewards: "Blog  Rewards",
    commentPostRewards: "Comment  Rewards",
    advancedSettings: "Advanced Settings",
    whoReceiveAnyRewards: "Who should receive any rewards?",
    add: "Add",
    email: "Email",
    regIntro:
      "Please cherish the registration resources, do not register more than one",
    programIntro: "Club Quality Programs",
    sap_title: "Scheduled Article Publication",
    sap_intro:
      "Scheduled article publication helps you preconfigure the articles you want to publish and releases them at the time you've set.",
    sap_remark: "Articles can be scheduled for a maximum of 7 days",
    sapAuthorizeTips:
      "Using the timed article posting feature will grant {username} Post permissions!",
    authorize: "Authorize",
    changeAuthorize: "Change Authorize",
    postTime: "Post Time(UTC)",
    wait: "wait",
    posted: "posted",
    confirmDelete: "Confirm delete?",
    toolsIntro: "Tools",
    removeAuthorization_title: "Remove Authorization",
    removeAuthorization_intro: "Remove Authorization",
    removeAuthorization_remark: "Remove Authorization",
    authorityManagement_title: "Authority Management",
    authorityManagement_intro:
      "POST Permission Management,  Delete Authorization",
    authorityManagement_remark:
      "POST Permission Management,  Delete Authorization",
    removeAuthorizeTips: "You will remove {username} Post permissions!",
    timeSpan: "Time Span",
    autoClaimReward_title: "Auto Claim Reward",
    autoClaimReward_intro: "Auto Claim Reward,Execute once every 5 minutes",
    autoClaimReward_remark: "Auto Claim Reward,Execute once every 5 minutes",
    autoFollowClubVote_title: "Follow the club  curation",
    autoFollowClubVote_intro: "Follow the club  curation",
    autoFollowClubVote_remark: "Follow the club account curation",
    chartNum: "Number of characters",
  },
  button: {
    bonusSBIRanking: "Bonus SBI Ranking",
    voteNewActivity: "Vote New Activity",
    voteJoin: "Vote ",
    close: "Close",
    voteJoined: "Voted",
    claim: "Claim",
    claimed: "Claimed",
    checking: "checking",
    rank: "Rank",
    login: "Login",
    flag: "Flag",
    resteem: "Resteem",
    edit: "Edit",
    subscribe: "SUBSCRIBE",
    joined: "JOINED",
    join: "Join",
    leave: "LEAVE",
    save: "Save",
    followed: "UnFollow",
    follow: "Follow",
    muted: "UnMute",
    mute: "mute",
    confirm: "Confirm",
    cancel: "Cancel",
    delete: "Delete",
    delegate: "Delegate",
    exchange: "Exchange",
    submit: "Submit",
    register: "Register",
    authorize: "Authorize",
    enter: "Enter",
    add: "Add",
    check: "check",
  },
  input: {
    search: "Search",
    exchangeuvfnumber: "please input exchange uvf number",
    InputUserName: "Please input UserName",
  },
  table: {
    activityTitle: "Activity Title",
    activityVoteNum: "Activity Vote Number",
    estimatedBonus: "Estimated Bonus",
    bonusType: "Bonus Type",
    activityStatus: "Activity Status",
    winnerName: "Winner Name",
    accumulatedBonus: "Accumulated Bonus",
    sbiBonus: "SBI Bonus",
    sbdBonus: "SBD Bonus",
    uvfBonus: "UVF Bonus",
    steemBonus: "STEEM Bonus",
    author: "Author",
    title: "Title",
    planPostTime: "Plan Post Time",
    postTime: "Post Time",
    postState: "State",
    message: "Message",
    oper: "Operation",
    userName: "User Name",
    permissionType: "Permission Type",
  },
  error: {
    userOrPasswordError: "UserName Or Password Error",
    systemError: "System Error",
    noAirDrop: "Sorry, you didn't get airdrop！",
    airDropClaimed: "Airdrop Claimed",
    airDropEnd: "Airdrop End",
    airDropNotBegin: "Airdrop not Begin",
    postError: "Post Error",
    uploadImageTypeError: "Uploaded avatar image format error!",
    uploadImageSizeError:
      "The size of the uploaded avatar image cannot exceed 2MB!",
    toError: "Please input to",
    transferAmountError: "Amount needs to be greater than 0",
    activeKeyError: "Active Key Error",
    powerUpError: "Power Up Error",
    powerDownError: "Power Down Error",
    transError: "Trans Error",
    balanceError: "Balance Error",
    delegateError: "Delegate Error",
    exchangeNumError: "Input Error",
    operError: "Operation Error",
    wrongPercentage: "The percentages need equal 100%",
    haveNullUser: "User Name is null",
    userNameExist: "User Name is Exist",
    haveNullEmail: "Email is null",
    emailExist: "Email Exist",
    userNameOrEmailIsReg: "Username or email already registered",
    regVerifyError:
      "Verification failed, please send feedback to Services@boylikegirl.club ",
    regIn7day: "The same IP has registered an account within seven days",
    sapDatetimeNotNull: "Post Time is null",
    insufficientPoints: "Insufficient Points",
  },
  success: {
    claimedSuccess: "Claimed Success",
    postSuccess: "Post Success",
    transSuccess: "Trans Success",
    powerUpSuccess: "Power Up Success",
    powerDownSuccess: "Power Down Success",
    delegateSuccess: "Delegate Success",
    exchangeSuccess: "Exchange Success",
    regVerifyEmailSend:
      "Verification email has been sent to the mailbox please check",
    regVerifySuccess:
      "Registration information verification is successful, account information will be sent to the registered mailbox, please check it!",
    authorizeSuccess: "Authorize Success",
    removeAuthorizeSuccess: "Remove Authorize Success",
    joinSuccess: "Join Success",
  },
  desc: {
    opening: "Opening",
    closed: "Closed",
    claimed: "Claimed",
    waitClaimed: "WaitClaimed",
  },
  refresh: {
    pulling: "Pull down to refresh",
    loosing: "Loosing to refresh",
    refresh: "Refreshing",
    success: "Refresh success",
  },
  loadmore: {
    loading: "Loading",
    finished: "No more data",
    error: "Request failed, click to reload",
  },
  operType: {
    Post: "Post",
    Comment: "Comment",
    Vote: "Vote",
    DaliySign: "Daliy Sign",
    ReadPost: "Read Post",
    JoinLuckyNum: "Join LuckyNum",
    SubscribeClub: "Subscribe Club",
    WintessVote: "Wintess Vote",
    WitnessProxy: "Witness Proxy",
    Delegation: "Delegation",
    FirstLand: "First Land",
    ExchangeUVF: "Exchange UVF",
  },
};

export default en;
