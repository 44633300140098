import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  key: 0,
  "class": "btn-inner--icon"
};
var _hoisted_2 = {
  key: 2,
  "class": "btn-inner--text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_resolveDynamicComponent($props.tag), {
    type: $props.tag === 'button' ? $props.nativeType : '',
    onClick: $options.handleClick,
    "class": _normalizeClass(["btn", $options.classes])
  }, {
    "default": _withCtx(function () {
      return [_ctx.$slots.icon || $props.icon && _ctx.$slots["default"] ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_renderSlot(_ctx.$slots, "icon", {}, function () {
        return [_createElementVNode("i", {
          "class": _normalizeClass($props.icon)
        }, null, 2)];
      })])) : _createCommentVNode("", true), !_ctx.$slots["default"] ? (_openBlock(), _createElementBlock("i", {
        key: 1,
        "class": _normalizeClass($props.icon)
      }, null, 2)) : _createCommentVNode("", true), _ctx.$slots.icon || $props.icon && _ctx.$slots["default"] ? (_openBlock(), _createElementBlock("span", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, function () {
        return [_createTextVNode(_toDisplayString($props.text), 1)];
      })])) : _createCommentVNode("", true), !_ctx.$slots.icon && !$props.icon ? _renderSlot(_ctx.$slots, "default", {
        key: 3
      }) : _createCommentVNode("", true)];
    }),
    _: 3
  }, 8, ["type", "onClick", "class"]);
}