import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "container-fluid mt--7"
};
var _hoisted_2 = {
  "class": "row"
};
var _hoisted_3 = {
  "class": "col"
};
var _hoisted_4 = {
  "class": "row align-items-right"
};
var _hoisted_5 = {
  "class": "text-right"
};
var _hoisted_6 = {
  "class": "table-responsive"
};
var _hoisted_7 = {
  style: {
    "width": "20% !important"
  }
};
var _hoisted_8 = {
  style: {
    "width": "15% !important"
  }
};
var _hoisted_9 = {
  scope: "row"
};
var _hoisted_10 = ["title"];
var _hoisted_11 = ["title"];
var _hoisted_12 = {
  "class": "align-items-right"
};
var _hoisted_13 = {
  "class": "text-danger"
};
var _hoisted_14 = {
  "class": "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_base_header = _resolveComponent("base-header");

  var _component_base_button = _resolveComponent("base-button");

  var _component_base_table = _resolveComponent("base-table");

  var _component_el_pagination = _resolveComponent("el-pagination");

  var _component_el_input = _resolveComponent("el-input");

  var _component_el_form_item = _resolveComponent("el-form-item");

  var _component_el_form = _resolveComponent("el-form");

  var _component_el_button = _resolveComponent("el-button");

  var _component_el_dialog = _resolveComponent("el-dialog");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_base_header, {
    type: "gradient-success",
    "class": "pb-6 pb-8 pt-5 pt-md-8"
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
    "class": _normalizeClass(["card shadow", $data.type === 'dark' ? 'bg-default' : ''])
  }, [_createElementVNode("div", {
    "class": _normalizeClass(["card-header border-0", $data.type === 'dark' ? 'bg-transparent' : ''])
  }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [!$data.isHavePermission && $data.isloadEnd && _ctx.loginData ? (_openBlock(), _createBlock(_component_base_button, {
    key: 0,
    size: "sm",
    type: "default",
    "class": "float-right",
    onClick: $options.authorize
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$t("button.authorize")), 1)];
    }),
    _: 1
  }, 8, ["onClick"])) : _createCommentVNode("", true), $data.isHavePermission && $data.isloadEnd ? (_openBlock(), _createBlock(_component_base_button, {
    key: 1,
    size: "sm",
    type: "primary",
    "class": "float-right",
    onClick: $options.addPost
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$t("button.add")), 1)];
    }),
    _: 1
  }, 8, ["onClick"])) : _createCommentVNode("", true)])])], 2), _createElementVNode("div", _hoisted_6, [_createVNode(_component_base_table, {
    "class": _normalizeClass(["table align-items-center table-flush", $data.type === 'dark' ? 'table-dark' : '']),
    "thead-classes": $data.type === 'dark' ? 'thead-dark' : 'thead-light',
    "tbody-classes": "list",
    data: $data.tableData.items,
    style: {
      "table-layout": "fixed"
    }
  }, {
    columns: _withCtx(function () {
      return [_createElementVNode("th", null, _toDisplayString(_ctx.$t("table.author")), 1), _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t("table.title")), 1), _createElementVNode("th", null, _toDisplayString(_ctx.$t("table.planPostTime")), 1), _createElementVNode("th", null, _toDisplayString(_ctx.$t("table.postTime")), 1), _createElementVNode("th", null, _toDisplayString(_ctx.$t("table.postState")), 1), _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t("table.message")), 1), _createElementVNode("th", null, _toDisplayString(_ctx.$t("table.oper")), 1)];
    }),
    "default": _withCtx(function (row) {
      return [_createElementVNode("th", _hoisted_9, _toDisplayString(row.item.author), 1), _createElementVNode("td", {
        style: {
          "width": "20% !important",
          "overflow": "hidden"
        },
        title: row.item.title
      }, _toDisplayString(row.item.title), 9, _hoisted_10), _createElementVNode("td", null, _toDisplayString(row.item.planPostTime), 1), _createElementVNode("td", null, _toDisplayString(row.item.postTime), 1), _createElementVNode("td", null, _toDisplayString(_ctx.$t("label." + (!row.item.isPosted ? "wait" : "posted"))), 1), _createElementVNode("td", {
        style: {
          "width": "15% !important",
          "overflow": "hidden"
        },
        title: row.item.successMessage ? row.item.successMessage : row.item.errorMessage
      }, _toDisplayString(row.item.successMessage ? row.item.successMessage : row.item.errorMessage), 9, _hoisted_11), _createElementVNode("td", null, [_createVNode(_component_base_button, {
        size: "sm",
        type: _ctx.secondary,
        "class": "mr-4",
        onClick: function onClick($event) {
          return $options.deleteSap(row.item.id);
        }
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t("button.delete")), 1)];
        }),
        _: 2
      }, 1032, ["type", "onClick"])])];
    }),
    _: 1
  }, 8, ["class", "thead-classes", "data"]), _createElementVNode("div", _hoisted_12, [$data.tableData.totalCount > 0 ? (_openBlock(), _createBlock(_component_el_pagination, {
    key: 0,
    onCurrentChange: $options.handleCurrentChange,
    "current-page": $data.currentPage,
    "page-size": $data.pageSize,
    layout: "prev, pager, next",
    total: $data.tableData.totalCount
  }, null, 8, ["onCurrentChange", "current-page", "page-size", "total"])) : _createCommentVNode("", true)])])], 2)])])]), _createVNode(_component_el_dialog, {
    modelValue: $data.dialogVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
      return $data.dialogVisible = $event;
    }),
    title: _ctx.$t('label.authorize'),
    width: "60%"
  }, {
    footer: _withCtx(function () {
      return [_createElementVNode("span", _hoisted_14, [_createVNode(_component_el_button, {
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return $data.dialogVisible = false;
        })
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t("button.cancel")), 1)];
        }),
        _: 1
      }), _createVNode(_component_el_button, {
        type: "primary",
        onClick: _cache[2] || (_cache[2] = function ($event) {
          return $options.confirmAuthorize();
        }),
        loading: $data.isLoading
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t("button.confirm")), 1)];
        }),
        _: 1
      }, 8, ["loading"])])];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("span", null, _toDisplayString(_ctx.$t("label.sapAuthorizeTips", {
        username: $data.program.username
      })), 1), !$data.isUseKeychain ? (_openBlock(), _createBlock(_component_el_form, {
        key: 0,
        "label-width": "90px"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_form_item, {
            label: _ctx.$t('label.activeKey')
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_el_input, {
                "show-password": "",
                modelValue: $data.acitveKey,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
                  return $data.acitveKey = $event;
                }),
                placeholder: _ctx.$t('label.InputActivePassWord'),
                "class": "input-with-select"
              }, null, 8, ["modelValue", "placeholder"])];
            }),
            _: 1
          }, 8, ["label"])];
        }),
        _: 1
      })) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", null, [_createElementVNode("small", _hoisted_13, _toDisplayString(_ctx.$t($data.errorMessage)), 1)], 512), [[_vShow, $data.isError]])];
    }),
    _: 1
  }, 8, ["modelValue", "title"])], 64);
}