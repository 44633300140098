import { renderSlot as _renderSlot, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["id", "aria-expanded"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["tab-pane fade", {
      'active show': $data.active
    }]),
    id: $props.id || $props.label,
    "aria-expanded": $data.active
  }, [_renderSlot(_ctx.$slots, "default")], 10, _hoisted_1)), [[_vShow, $data.active]]);
}