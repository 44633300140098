import {
  formatter
} from "@steemit/steem-js";
import dsteem from "../unit/DsteemUtil";
/**
 *
 * @param {*} 提取图片
 * @returns
 */
export const extractImage = function (body) {
  const pattern = /!\[(.*?)\]\((.*?)\)/gm;
  let matcher;
  let imageList = [];
  while ((matcher = pattern.exec(body)) !== null) {
    /* console.log(matcher); */
    imageList.push(matcher[2]);
  }

  let imgReg = /<img.*?(?:>|\/>)/gi;
  let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
  let arr = body.match(imgReg); // arr 为包含所有img标签的数组
  if (arr) {
    for (var i = 0; i < arr.length; i++) {
      var src = arr[i].match(srcReg);
      //获取图片地址

      imageList.push(src[1]);
    }
  }
  return imageList;
};

/**
 * 计算金额的前置
 */
export const getPricePerSteem = async function () {
  let feed_price = await dsteem.getCurrentMedianHistoryPrice();

  if (feed_price) {
    let pricePerSteem = formatter.pricePerSteem(feed_price);

    return pricePerSteem;
  }
  return undefined;
};
/**
 * 获取SbdPrintRate
 */
export const getSbdPrintRate = async function () {
  const dynamicGlobalProperties = await dsteem.getDynamicGlobalProperties();

  return dynamicGlobalProperties.sbd_print_rate;
};

/**
 * 获取帐号SP
 */
export const getGetSp = async function (account) {
  const avail =
    parseFloat(account.vesting_shares) -
    (parseFloat(account.to_withdraw) - parseFloat(account.withdrawn)) / 1e6 -
    parseFloat(account.delegated_vesting_shares) +
    parseFloat(account.received_vesting_shares);
  var props = await dsteem.getDynamicGlobalProperties();
  const sp = parseFloat(
    parseFloat(props.total_vesting_fund_steem) *
    (parseFloat(avail) / parseFloat(props.total_vesting_shares)),
    6
  ).toFixed(2);

  return sp;
};

export default {
  extractImage,
  getPricePerSteem,
  getSbdPrintRate,
  getGetSp,
};