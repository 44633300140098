import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "container-fluid d-flex align-items-center"
};
var _hoisted_2 = {
  "class": "row"
};
var _hoisted_3 = {
  "class": "col-lg-10 col-md-12"
};
var _hoisted_4 = {
  "class": "text-white mt-0 mb-5"
};
var _hoisted_5 = {
  "class": "container-fluid mt--7"
};
var _hoisted_6 = {
  "class": "row justify-content-center"
};
var _hoisted_7 = {
  "class": "text-muted mb-4"
};
var _hoisted_8 = {
  role: "form"
};
var _hoisted_9 = {
  "class": "float-left"
};
var _hoisted_10 = {
  "class": "float-right"
};
var _hoisted_11 = {
  "class": "text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_base_header = _resolveComponent("base-header");

  var _component_big = _resolveComponent("big");

  var _component_base_input = _resolveComponent("base-input");

  var _component_el_button = _resolveComponent("el-button");

  var _component_card = _resolveComponent("card");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_base_header, {
    type: "gradient-success",
    "class": "pb-6 pt-2 pt-md-7"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("label.pointsMarketIntro")), 1)])])])];
    }),
    _: 1
  }), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_card, {
    type: "secondary",
    shadow: "",
    "header-classes": "bg-white pb-5",
    "body-classes": "px-lg-5 py-lg-5",
    "class": "border-0 w-75"
  }, {
    "default": _withCtx(function () {
      var _$data$market;

      return [_createElementVNode("div", _hoisted_7, [_createVNode(_component_big, null, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t("label.marketuvfexchange")), 1)];
        }),
        _: 1
      })]), _createElementVNode("form", _hoisted_8, [_createVNode(_component_base_input, {
        formClasses: "input-group-alternative mb-3",
        placeholder: _ctx.$t('input.exchangeuvfnumber'),
        "addon-left-icon": "ni ni-money-coins",
        modelValue: $data.exchangeNum,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.exchangeNum = $event;
        })
      }, null, 8, ["placeholder", "modelValue"]), _createElementVNode("div", _hoisted_9, [_createElementVNode("small", null, _toDisplayString(_ctx.$t("label.pointExhangeUvfRatio")) + ":1UVF=" + _toDisplayString((_$data$market = $data.market) === null || _$data$market === void 0 ? void 0 : _$data$market.exchangeFee) + " Points", 1)]), _createElementVNode("div", _hoisted_10, [_createElementVNode("small", null, _toDisplayString(_ctx.$t("label.balance")) + ":" + _toDisplayString($data.points) + " Points", 1)]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_el_button, {
        loading: $data.isLogining,
        type: "primary",
        "class": "my-4",
        onClick: $options.exchange
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t("button.exchange")), 1)];
        }),
        _: 1
      }, 8, ["loading", "onClick"])])])];
    }),
    _: 1
  })])])]);
}