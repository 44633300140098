import { randomString } from "./stringUtils";
export default {
  name: "base-checkbox",
  model: {
    prop: "checked"
  },
  props: {
    checked: {
      type: [Array, Boolean],
      description: "Whether checkbox is checked"
    },
    disabled: {
      type: Boolean,
      description: "Whether checkbox is disabled"
    },
    inline: {
      type: Boolean,
      description: "Whether checkbox is inline"
    }
  },
  data: function data() {
    return {
      cbId: "",
      touched: false
    };
  },
  computed: {
    model: {
      get: function get() {
        return this.checked;
      },
      set: function set(value) {
        this.$emit("clickCheckBox", value);
      }
    }
  },
  mounted: function mounted() {
    this.cbId = randomString();
  }
};