import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "row"
};
var _hoisted_2 = {
  "class": "col"
};
var _hoisted_3 = {
  key: 0,
  "class": "card-title text-uppercase text-muted mb-0"
};
var _hoisted_4 = {
  key: 1,
  "class": "h2 font-weight-bold mb-0"
};
var _hoisted_5 = {
  key: 0,
  "class": "col-auto"
};
var _hoisted_6 = {
  "class": "mt-3 mb-0 text-sm"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_card = _resolveComponent("card");

  return _openBlock(), _createBlock(_component_card, {
    "class": "card-stats",
    "show-footer-line": true
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, function () {
        return [$props.title ? (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString($props.title), 1)) : _createCommentVNode("", true), $props.subTitle ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.subTitle), 1)) : _createCommentVNode("", true)];
      })]), _ctx.$slots.icon || $props.icon ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_renderSlot(_ctx.$slots, "icon", {}, function () {
        return [_createElementVNode("div", {
          "class": _normalizeClass(["icon icon-shape text-white rounded-circle shadow", ["bg-".concat($props.type), $props.iconClasses]])
        }, [_createElementVNode("i", {
          "class": _normalizeClass($props.icon)
        }, null, 2)], 2)];
      })])) : _createCommentVNode("", true)]), _createElementVNode("p", _hoisted_6, [_renderSlot(_ctx.$slots, "footer")])];
    }),
    _: 3
  });
}