import "core-js/modules/es.number.constructor.js";
import { randomString } from "./stringUtils";
export default {
  name: "base-radio",
  props: {
    name: {
      type: [String, Number],
      description: "Radio label"
    },
    disabled: {
      type: Boolean,
      description: "Whether radio is disabled"
    },
    value: {
      type: [String, Boolean],
      description: "Radio value"
    },
    inline: {
      type: Boolean,
      description: "Whether radio is inline"
    }
  },
  data: function data() {
    return {
      cbId: ""
    };
  },
  computed: {
    model: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit("input", value);
      }
    },
    inlineClass: function inlineClass() {
      if (this.inline) {
        return "form-check-inline";
      }

      return "";
    }
  },
  mounted: function mounted() {
    this.cbId = randomString();
  }
};