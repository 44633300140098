import "core-js/modules/es.array.concat.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "card"
};
var _hoisted_2 = {
  "class": "card-header border-0"
};
var _hoisted_3 = {
  "class": "row align-items-center"
};
var _hoisted_4 = {
  "class": "col"
};
var _hoisted_5 = {
  "class": "mb-0"
};
var _hoisted_6 = {
  "class": "col text-right"
};
var _hoisted_7 = {
  "class": "table-responsive"
};
var _hoisted_8 = {
  scope: "row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");

  var _component_base_table = _resolveComponent("base-table");

  var _component_vote_model = _resolveComponent("vote-model");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("label.activityList")), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("a", {
    href: "javascript:void(0)",
    "class": "btn btn-sm btn-primary",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.voteNewActivity && $options.voteNewActivity.apply($options, arguments);
    })
  }, _toDisplayString(_ctx.$t("button.voteNewActivity")), 1)])])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_base_table, {
    "thead-classes": "thead-light",
    data: $data.tableData
  }, {
    columns: _withCtx(function () {
      return [_createElementVNode("th", null, _toDisplayString(_ctx.$t("table.activityTitle")), 1), _createElementVNode("th", null, _toDisplayString(_ctx.$t("table.bonusType")), 1), _createElementVNode("th", null, _toDisplayString(_ctx.$t("table.activityStatus")), 1)];
    }),
    "default": _withCtx(function (row) {
      return [_createElementVNode("th", _hoisted_8, [_createVNode(_component_router_link, {
        to: "/@".concat(row.item.username, "/").concat(row.item.permlink)
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(row.item.postsTitle), 1)];
        }),
        _: 2
      }, 1032, ["to"])]), _createElementVNode("td", null, _toDisplayString(row.item.priceType == 0 ? "STEEM" : "SBD"), 1), _createElementVNode("td", null, [_createElementVNode("span", {
        "class": _normalizeClass(["text-success mr-3", !row.item.isClosed ? 'text-success' : 'text-danger'])
      }, _toDisplayString(row.item.isClosed ? _ctx.$t("desc.closed") : _ctx.$t("desc.opening")), 3)])];
    }),
    _: 1
  }, 8, ["data"])])]), _createVNode(_component_vote_model, {
    ref: "voteModel",
    post: $data.newActivity,
    showModel: $data.showModel,
    onCloseModel: $options.closeModel
  }, null, 8, ["post", "showModel", "onCloseModel"])], 64);
}