import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "card"
};
var _hoisted_2 = {
  "class": "card-header border-0"
};
var _hoisted_3 = {
  "class": "row align-items-center"
};
var _hoisted_4 = {
  "class": "col"
};
var _hoisted_5 = {
  "class": "mb-0"
};
var _hoisted_6 = {
  "class": "col text-right"
};
var _hoisted_7 = {
  "class": "table-responsive"
};
var _hoisted_8 = {
  scope: "row"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_base_table = _resolveComponent("base-table");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("label.bonusRanking")), 1)]), _createElementVNode("div", _hoisted_6, _toDisplayString($data.sbiInfo ? _ctx.$t("label.sbiBalance") + ":" + $data.sbiInfo.shares : ""), 1)])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_base_table, {
    "thead-classes": "thead-light",
    data: $data.tableData
  }, {
    columns: _withCtx(function () {
      return [_createElementVNode("th", null, _toDisplayString(_ctx.$t("table.winnerName")), 1), _createElementVNode("th", null, _toDisplayString(_ctx.$t("table.steemBonus")), 1), _createElementVNode("th", null, _toDisplayString(_ctx.$t("table.uvfBonus")), 1)];
    }),
    "default": _withCtx(function (row) {
      return [_createElementVNode("th", _hoisted_8, _toDisplayString(row.item.winner), 1), _createElementVNode("td", null, _toDisplayString(row.item.steemPrize), 1), _createElementVNode("td", null, _toDisplayString(row.item.uvfPrize), 1)];
    }),
    _: 1
  }, 8, ["data"])])]);
}