import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = ["aria-hidden"];
var _hoisted_2 = ["aria-hidden"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["modal fade", [{
      'show d-block': $props.show
    }, {
      'd-none': !$props.show
    }, {
      'modal-mini': $props.type === 'mini'
    }]]),
    onClick: _cache[1] || (_cache[1] = _withModifiers(function () {
      return $options.closeModal && $options.closeModal.apply($options, arguments);
    }, ["self"])),
    tabindex: "-1",
    role: "dialog",
    "aria-hidden": !$props.show
  }, [_createElementVNode("div", {
    "class": _normalizeClass(["modal-dialog modal-dialog-centered", [{
      'modal-notice': $props.type === 'notice'
    }, $props.modalClasses]])
  }, [_createElementVNode("div", {
    "class": _normalizeClass(["modal-content", [$props.gradient ? "bg-gradient-".concat($props.gradient) : '', $props.modalContentClasses]])
  }, [_ctx.$slots.header ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    "class": _normalizeClass(["modal-header", [$props.headerClasses]])
  }, [_renderSlot(_ctx.$slots, "header"), _renderSlot(_ctx.$slots, "close-button", {}, function () {
    return [$props.showClose ? (_openBlock(), _createElementBlock("button", {
      key: 0,
      type: "button",
      "class": "close",
      onClick: _cache[0] || (_cache[0] = function () {
        return $options.closeModal && $options.closeModal.apply($options, arguments);
      }),
      "data-dismiss": "modal",
      "aria-label": "Close"
    }, [_createElementVNode("span", {
      "aria-hidden": !$props.show
    }, "×", 8, _hoisted_2)])) : _createCommentVNode("", true)];
  })], 2)) : _createCommentVNode("", true), _createElementVNode("div", {
    "class": _normalizeClass(["modal-body", $props.bodyClasses])
  }, [_renderSlot(_ctx.$slots, "default")], 2), _ctx.$slots.footer ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    "class": _normalizeClass(["modal-footer", $props.footerClasses])
  }, [_renderSlot(_ctx.$slots, "footer")], 2)) : _createCommentVNode("", true)], 2)], 2)], 10, _hoisted_1)), [[_vShow, $props.show]]);
}