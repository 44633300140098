import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  id: "app"
};
export function render(_ctx, _cache) {
  var _component_router_view = _resolveComponent("router-view");

  var _directive_wechat_title = _resolveDirective("wechat-title");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_router_view, null, null, 512), [[_directive_wechat_title, _ctx.$route.meta.title]])]);
}