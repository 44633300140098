export default {
  name: 'ImojiSelector',
  data: function data() {
    return {
      currentCat: 'FrequentlyUsed',
      emojis: {
        FrequentlyUsed: ['😃', '😘', '😂', '😳', '😍', '👏', '👍', '👎', '😁', '😉', '😠', '😞', '😥', '😭', '😝', '😡', '❤', '💔', '😣', '😔', '😄', '😷', '😚', '😓', '😊', '😢', '😜', '😨', '😰', '😲', '😏', '😱', '😪', '😖', '😌', '😒', '👻', '🎅', '👧', '👦', '👩', '👨', '🐶', '🐱', '👊', '✊', '✌', '💪', '👆', '👇', '👉', '👈', '👌', '💩'],
        Symbols0: ['🤗', '😎', '🤓', '👩‍💻', '👨‍💻', '🙄', '😭', '😨', '🤪', '🎉', '🤔', '🐵', '😇', '🤬', '🐈', '😹', '🙀', '🇨🇳', '👮', '🐕', '✅', '👋', '🔥', '🐛', '🍉', '👽', '🤖', '⌚', '🤝', '🏳️‍🌈', '🚩', '💤', '®', '©', '💯', '™', '💻', '📅', '📌', '✉', '⌨', '📗', '🤳', '🛌', '🎣', '🎨', '🎧', '🎸', '🎤', '🏸', '🏀', '⚽', '🎮', '🏊'],
        Symbols1: ['🍗', '🦄', '🔞', '🙏', '☀', '🌙', '🌟', '⚡', '☁', '☔', '🍁', '🌻', '🍃', '👗', '🎀', '👄', '🌹', '☕', '🎂', '🕙', '🍺', '🔍', '📱', '🏠', '🚗', '🎁', '⚽', '💣', '💎', '💊', '🤮', '🏆', '👿']
      }
    };
  },
  computed: {
    currentEmojis: function currentEmojis() {
      return this.emojis[this.currentCat];
    }
  }
};