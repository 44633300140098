import "core-js/modules/es.number.constructor.js";
export default {
  name: "base-pagination",
  props: {
    pageCount: {
      type: Number,
      "default": 0,
      description: "Pagination page count. This should be specified in combination with perPage"
    },
    perPage: {
      type: Number,
      "default": 10,
      description: "Pagination per page. Should be specified with total or pageCount"
    },
    total: {
      type: [String, Number],
      "default": 0,
      description: "Can be specified instead of pageCount. The page count in this case will be total/perPage"
    },
    value: {
      type: Number,
      "default": 1,
      description: "Pagination value"
    },
    size: {
      type: String,
      "default": "",
      description: "Pagination size"
    },
    align: {
      type: String,
      "default": "",
      description: "Pagination alignment (e.g center|start|end)"
    }
  },
  computed: {
    totalPages: function totalPages() {
      if (this.pageCount > 0) return this.pageCount;

      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage);
      }

      return 1;
    },
    pagesToDisplay: function pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }

      return this.defaultPagesToDisplay;
    },
    minPage: function minPage() {
      if (this.value >= this.pagesToDisplay) {
        var pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        var newMaxPage = pagesToAdd + this.value;

        if (newMaxPage > this.totalPages) {
          return this.totalPages - this.pagesToDisplay + 1;
        }

        return this.value - pagesToAdd;
      } else {
        return 1;
      }
    },
    maxPage: function maxPage() {
      if (this.value >= this.pagesToDisplay) {
        var pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        var newMaxPage = pagesToAdd + this.value;

        if (newMaxPage < this.totalPages) {
          return newMaxPage;
        } else {
          return this.totalPages;
        }
      } else {
        return this.pagesToDisplay;
      }
    }
  },
  data: function data() {
    return {
      defaultPagesToDisplay: 5
    };
  },
  methods: {
    range: function range(min, max) {
      var arr = [];

      for (var i = min; i <= max; i++) {
        arr.push(i);
      }

      return arr;
    },
    changePage: function changePage(item) {
      this.$emit("input", item);
    },
    nextPage: function nextPage() {
      if (this.value < this.totalPages) {
        this.$emit("input", this.value + 1);
      }
    },
    prevPage: function prevPage() {
      if (this.value > 1) {
        this.$emit("input", this.value - 1);
      }
    }
  },
  watch: {
    perPage: function perPage() {
      this.$emit("input", 1);
    },
    total: function total() {
      this.$emit("input", 1);
    }
  }
};