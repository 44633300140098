import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("table", {
    "class": _normalizeClass(["table tablesorter", $options.tableClass])
  }, [_createElementVNode("thead", {
    "class": _normalizeClass($props.theadClasses)
  }, [_createElementVNode("tr", null, [_renderSlot(_ctx.$slots, "columns", {
    columns: $props.columns
  }, function () {
    return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, function (column) {
      return _openBlock(), _createElementBlock("th", {
        key: column
      }, _toDisplayString(column), 1);
    }), 128))];
  })])], 2), _createElementVNode("tbody", {
    "class": _normalizeClass($props.tbodyClasses)
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, function (item, index) {
    return _openBlock(), _createElementBlock("tr", {
      key: index
    }, [_renderSlot(_ctx.$slots, "default", {
      item: item
    }, function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, function (column) {
        return _openBlock(), _createElementBlock("td", {
          key: column
        }, [$options.hasValue(item, column) ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [_createTextVNode(_toDisplayString($options.itemValue(item, column)), 1)], 64)) : _createCommentVNode("", true)]);
      }), 128))];
    })]);
  }), 128))], 2)], 2);
}