import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, { retries: 5 });

axios.defaults.timeout = 30000;

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.status);
          return;
        } else {
          reject(500);
        }
      });
  });
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.status);
          return;
        }
        reject(500);
      });
  });
}

export function put(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .put(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err.response) {
          reject(err.response.status);
          return;
        }
        reject(500);
      });
  });
}

export async function uploadImage(url, data, callback) {
  return await new Promise((resolve, reject) => {
    axios({
      url: url, //请求地址
      method: "POST",
      onUploadProgress: function (progressEvent) {
        //原生获取上传进度的事件
        if (progressEvent.lengthComputable) {
          //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
          //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
          if (callback) callback(progressEvent);
        }
      },
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
      if (res.status === 200) {
        // 将后端返回的url放在md中图片的指定位置

        resolve(res.data.url);
      } else {
        reject(res.message);
      }
    });
  });
}
