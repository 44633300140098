import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, withKeys as _withKeys, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue";
var _hoisted_1 = {
  "class": "container-fluid mt-2"
};
var _hoisted_2 = {
  "class": "col-xl-12 order-xl-1"
};
var _hoisted_3 = {
  "class": ""
};
var _hoisted_4 = {
  "class": "form-group"
};
var _hoisted_5 = {
  "class": ""
};
var _hoisted_6 = {
  "class": "form-group"
};
var _hoisted_7 = {
  "class": ""
};
var _hoisted_8 = {
  "class": "form-control-label"
};
var _hoisted_9 = {
  "class": ""
};
var _hoisted_10 = {
  "class": "form-group"
};
var _hoisted_11 = {
  "class": "form-group"
};
var _hoisted_12 = {
  "class": "text-success"
};
var _hoisted_13 = {
  "class": "form-group"
};
var _hoisted_14 = {
  key: 0,
  "class": ""
};
var _hoisted_15 = {
  "class": "form-control-label"
};
var _hoisted_16 = {
  "class": "form-group"
};
var _hoisted_17 = {
  "class": "form-control-label"
};
var _hoisted_18 = {
  "class": "form-group"
};
var _hoisted_19 = {
  key: 1,
  "class": ""
};
var _hoisted_20 = {
  "class": "form-group"
};
var _hoisted_21 = {
  key: 2,
  "class": ""
};
var _hoisted_22 = {
  "class": "form-group"
};
var _hoisted_23 = {
  "class": "text-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_base_header = _resolveComponent("base-header");

  var _component_base_input = _resolveComponent("base-input");

  var _component_mavon_editor = _resolveComponent("mavon-editor");

  var _component_el_tag = _resolveComponent("el-tag");

  var _component_el_input = _resolveComponent("el-input");

  var _component_el_button = _resolveComponent("el-button");

  var _component_el_link = _resolveComponent("el-link");

  var _component_el_date_picker = _resolveComponent("el-date-picker");

  var _component_el_input_number = _resolveComponent("el-input-number");

  var _component_card = _resolveComponent("card");

  var _component_el_progress = _resolveComponent("el-progress");

  var _component_el_dialog = _resolveComponent("el-dialog");

  var _component_PostAdvancedSettings = _resolveComponent("PostAdvancedSettings");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_base_header, {
    type: "gradient-success",
    "class": "pb-1 pb-1 pt-1 pt-md-6"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", null, _toDisplayString(_ctx.$t("label.postIntro")), 1)];
    }),
    _: 1
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_card, {
    shadow: "",
    type: "secondary"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("form", {
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(function () {}, ["prevent"]))
      }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_base_input, {
        alternative: "",
        label: _ctx.$t('label.postTitle'),
        modelValue: $data.postTitle,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.postTitle = $event;
        })
      }, null, 8, ["label", "modelValue"])])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_base_input, {
        alternative: "",
        label: _ctx.$t('label.postBody')
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_mavon_editor, {
            language: $data.language,
            onImgAdd: $options.imgAdd,
            ref: "md",
            onSave: $options.saveDraft,
            onChange: $options.saveDraft,
            modelValue: $data.postBody,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return $data.postBody = $event;
            }),
            subfield: $data.subfield,
            defaultOpen: $data.defaultOpen,
            html: true,
            toolbars: $data.toolbars
          }, null, 8, ["language", "onImgAdd", "onSave", "onChange", "modelValue", "subfield", "defaultOpen", "toolbars"])];
        }),
        _: 1
      }, 8, ["label"])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("label.chartNum")) + ":" + _toDisplayString($data.enChartNum), 1)])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_base_input, {
        alternative: "",
        label: _ctx.$t('label.postTag')
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.dynamicTags, function (tag, index) {
            return _openBlock(), _createBlock(_component_el_tag, {
              key: tag,
              closable: !$data.isEdit || index != 0,
              "disable-transitions": false,
              onClose: function onClose($event) {
                return $options.handleClose(tag);
              }
            }, {
              "default": _withCtx(function () {
                return [_createTextVNode(_toDisplayString(tag), 1)];
              }),
              _: 2
            }, 1032, ["closable", "onClose"]);
          }), 128)), $data.inputVisible ? (_openBlock(), _createBlock(_component_el_input, {
            key: 0,
            "class": "input-new-tag",
            modelValue: $data.inputValue,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return $data.inputValue = $event;
            }),
            ref: "saveTagInput",
            size: "small",
            onKeyup: _withKeys($options.handleInputConfirm, ["enter"]),
            onBlur: $options.handleInputConfirm
          }, null, 8, ["modelValue", "onKeyup", "onBlur"])) : (_openBlock(), _createBlock(_component_el_button, {
            key: 1,
            "class": "button-new-tag",
            size: "small",
            onClick: $options.showInput
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode("+" + _toDisplayString(_ctx.$t("label.addTag")), 1)];
            }),
            _: 1
          }, 8, ["onClick"]))])];
        }),
        _: 1
      }, 8, ["label"])])]), _createElementVNode("div", null, [_createElementVNode("div", _hoisted_11, [_createVNode(_component_el_link, {
        type: "success",
        underline: false,
        onClick: _cache[3] || (_cache[3] = function ($event) {
          return $data.isShowModelAdvancedSetting = true;
        })
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t("label.advancedSettings")), 1)];
        }),
        _: 1
      })])]), _withDirectives(_createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, " Beneficiaries: " + _toDisplayString($data.beneficiarieList.length - 1) + " set ", 1)], 512), [[_vShow, $data.beneficiarieList.length > 1]]), $data.sap == 'sappost' ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("label.postTime")), 1), _createElementVNode("div", _hoisted_16, [_createVNode(_component_el_date_picker, {
        modelValue: $data.postTime,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
          return $data.postTime = $event;
        }),
        type: "datetime",
        placeholder: "Select date and time",
        format: "YYYY-MM-DD HH:mm:ss",
        "value-format": "YYYY-MM-DD HH:mm:ss"
      }, null, 8, ["modelValue"])]), _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("label.timeSpan")), 1), _createElementVNode("div", _hoisted_18, [_createVNode(_component_el_input_number, {
        modelValue: $data.timeSpan,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
          return $data.timeSpan = $event;
        }),
        label: "描述文字"
      }, null, 8, ["modelValue"])])])) : _createCommentVNode("", true), $data.sap != 'sappost' && $data.isLoaded ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_createVNode(_component_el_button, {
        loading: $data.postLoad,
        type: "success",
        "class": "my-4",
        onClick: $options.publish
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t("label.publish")), 1)];
        }),
        _: 1
      }, 8, ["loading", "onClick"])])])) : $data.isHavePermission ? (_openBlock(), _createElementBlock("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, [_createVNode(_component_el_button, {
        loading: $data.postLoad,
        type: "success",
        "class": "my-4",
        onClick: $options.sapSubmit
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t("button.submit")), 1)];
        }),
        _: 1
      }, 8, ["loading", "onClick"])])])) : _createCommentVNode("", true)], 32)];
    }),
    _: 1
  })])]), _createVNode(_component_el_dialog, {
    title: _ctx.$t('label.uploadImageProgress'),
    modelValue: $data.dialogVisible,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
      return $data.dialogVisible = $event;
    })
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_23, [_createVNode(_component_el_progress, {
        type: "circle",
        percentage: $data.uploadPercentage,
        status: $data.uploadPercentage === 100 ? 'success' : undefined
      }, null, 8, ["percentage", "status"])])];
    }),
    _: 1
  }, 8, ["title", "modelValue"]), _createVNode(_component_PostAdvancedSettings, {
    isShowModelAdvancedSetting: $data.isShowModelAdvancedSetting,
    "onUpdate:isShowModelAdvancedSetting": _cache[8] || (_cache[8] = function ($event) {
      return $data.isShowModelAdvancedSetting = $event;
    }),
    onSaveAdvancedSettings: $options.saveAdvancedSettings,
    modelValue: $data.beneficiarieList,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = function ($event) {
      return $data.beneficiarieList = $event;
    })
  }, null, 8, ["isShowModelAdvancedSetting", "onSaveAdvancedSettings", "modelValue"])], 64);
}