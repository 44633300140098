import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_sidebar_item = _resolveComponent("sidebar-item");

  var _component_side_bar = _resolveComponent("side-bar");

  return _openBlock(), _createBlock(_component_side_bar, {
    "background-color": _ctx.sidebarBackground,
    "short-title": "Boylikegirl",
    title: "Boylikegirl"
  }, {
    links: _withCtx(function () {
      return [_withDirectives(_createVNode(_component_sidebar_item, {
        link: {
          name: _ctx.$t('menu.post'),
          icon: 'fa fa-pencil text-primary',
          path: "/post"
        }
      }, null, 8, ["link"]), [[_vShow, $data.isLogin]]), _withDirectives(_createVNode(_component_sidebar_item, {
        link: {
          name: _ctx.$t('menu.blog'),
          icon: 'ni ni-paper-diploma text-primary',
          path: "/@".concat($data.userName)
        }
      }, null, 8, ["link"]), [[_vShow, $data.isLogin && $data.userName]]), _createVNode(_component_sidebar_item, {
        link: {
          name: _ctx.$t('menu.clubentrance'),
          icon: 'ni ni-building text-primary',
          path: '/trending/hive-150487'
        }
      }, null, 8, ["link"]), _createVNode(_component_sidebar_item, {
        link: {
          name: _ctx.$t('menu.communities'),
          icon: 'ni ni-building text-primary',
          path: '/Community'
        }
      }, null, 8, ["link"]), _withDirectives(_createVNode(_component_sidebar_item, {
        link: {
          name: _ctx.$t('menu.friends'),
          icon: 'fa fa-users text-primary',
          path: '/friend'
        }
      }, null, 8, ["link"]), [[_vShow, $data.isLogin]]), _withDirectives(_createVNode(_component_sidebar_item, {
        link: {
          name: _ctx.$t('menu.myCommunities'),
          icon: 'fa fa-building text-primary',
          path: '/MyCommunities'
        }
      }, null, 8, ["link"]), [[_vShow, $data.isLogin]]), _createVNode(_component_sidebar_item, {
        link: {
          name: _ctx.$t('menu.pointsmarket'),
          icon: 'fa fa-shopping-bag text-primary',
          path: '/pointsMarket'
        }
      }, null, 8, ["link"]), _createVNode(_component_sidebar_item, {
        link: {
          name: _ctx.$t('menu.program'),
          icon: 'fa fa-train text-primary',
          path: '/program'
        }
      }, null, 8, ["link"]), _createVNode(_component_sidebar_item, {
        link: {
          name: _ctx.$t('menu.lucknum'),
          icon: 'ni ni-tv-2 text-primary',
          path: '/lucknum'
        }
      }, null, 8, ["link"]), _createVNode(_component_sidebar_item, {
        link: {
          name: _ctx.$t('menu.tools'),
          icon: 'fa fa-toolbox text-primary',
          path: '/tools'
        }
      }, null, 8, ["link"]), _createVNode(_component_sidebar_item, {
        link: {
          name: _ctx.$t('menu.airdrop'),
          icon: 'ni ni-spaceship text-blue',
          path: '/airdrop'
        }
      }, null, 8, ["link"]), _withDirectives(_createVNode(_component_sidebar_item, {
        link: {
          name: _ctx.$t('menu.setting'),
          icon: 'fa fa-cogs text-primary',
          path: '/setting'
        }
      }, null, 8, ["link"]), [[_vShow, $data.isLogin]]), _withDirectives(_createVNode(_component_sidebar_item, {
        link: {
          name: _ctx.$t('menu.wallet'),
          icon: 'fa fa-usd text-primary',
          path: "/wallet/@".concat($data.userName)
        }
      }, null, 8, ["link"]), [[_vShow, $data.isLogin && $data.userName]])];
    }),
    _: 1
  }, 8, ["background-color"]);
}