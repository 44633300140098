import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import NavbarToggleButton from "@/components/NavbarToggleButton";
import UserLoginView from "@/components/UserLoginView.vue";
export default {
  name: "sidebar",
  components: {
    NavbarToggleButton: NavbarToggleButton,
    UserLoginView: UserLoginView
  },
  props: {
    logo: {
      type: String,
      "default": "/img/brand/logo.png",
      description: "app logo"
    },
    autoClose: {
      type: Boolean,
      "default": true,
      description: "Whether sidebar should autoclose on mobile when clicking an item"
    }
  },
  data: function data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      showLoginModel: false,
      isLogin: false,
      isOtherLogin: false,
      languageValue: localStorage.getItem("locale"),
      languageOptions: [{
        value: "zh",
        label: "中文"
      }, {
        value: "en",
        label: "English"
      }],
      rpcOptions: [],
      rpcValue: localStorage.getItem("rpcNode")
    };
  },
  watch: {
    languageValue: function languageValue() {
      localStorage.setItem("locale", this.languageValue);
      location.reload();
    },
    rpcValue: function rpcValue() {
      localStorage.setItem("rpcNode", this.rpcValue);
      location.reload();
    }
  },
  provide: function provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    closeSidebar: function closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar: function showSidebar() {
      this.$sidebar.displaySidebar(true);
    }
  },
  beforeUnmount: function beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  mounted: function mounted() {
    var _this = this;

    var rpcList = process.env.VUE_APP_STEEM_RPC.split("|");
    rpcList.forEach(function (element) {
      _this.rpcOptions.push({
        value: element,
        label: element
      });
    });
    this.rpcValue = localStorage.getItem("rpcNode");
  }
};