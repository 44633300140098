import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
export default {
  name: "base-table",
  props: {
    columns: {
      type: Array,
      "default": function _default() {
        return [];
      },
      description: "Table columns"
    },
    data: {
      type: Array,
      "default": function _default() {
        return [];
      },
      description: "Table data"
    },
    type: {
      type: String,
      // striped | hover
      "default": "",
      description: "Whether table is striped or hover type"
    },
    theadClasses: {
      type: String,
      "default": "",
      description: "<thead> css classes"
    },
    tbodyClasses: {
      type: String,
      "default": "",
      description: "<tbody> css classes"
    }
  },
  computed: {
    tableClass: function tableClass() {
      return this.type && "table-".concat(this.type);
    },
    colsWithValue: function colsWithValue() {
      var _this = this;

      return function (row) {
        return _this.columns.filter(function (column) {
          return _this.hasValue(row, column);
        });
      };
    }
  },
  methods: {
    hasValue: function hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue: function itemValue(item, column) {
      return item[column.toLowerCase()];
    }
  }
};