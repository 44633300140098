import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toHandlers as _toHandlers, mergeProps as _mergeProps } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 0,
  "class": "input-group-prepend"
};
var _hoisted_3 = {
  "class": "input-group-text"
};
var _hoisted_4 = ["value"];
var _hoisted_5 = {
  key: 1,
  "class": "input-group-append"
};
var _hoisted_6 = {
  "class": "input-group-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["form-group", [{
      'input-group': $options.hasIcon
    }, {
      'has-danger': $props.error
    }, {
      focused: $data.focused
    }, {
      'has-label': $props.label || _ctx.$slots.label
    }, {
      'has-success': $props.valid === true
    }, {
      'has-danger': $props.valid === false
    }, $props.formClasses]])
  }, [_renderSlot(_ctx.$slots, "label", {}, function () {
    return [$props.label ? (_openBlock(), _createElementBlock("label", {
      key: 0,
      "class": _normalizeClass(["form-control-label", $props.labelClasses])
    }, [_createTextVNode(_toDisplayString($props.label) + " ", 1), $props.required ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*")) : _createCommentVNode("", true)], 2)) : _createCommentVNode("", true)];
  }), $props.addonLeftIcon || _ctx.$slots.addonLeft ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, [_renderSlot(_ctx.$slots, "addonLeft", {}, function () {
    return [_createElementVNode("i", {
      "class": _normalizeClass($props.addonLeftIcon)
    }, null, 2)];
  })])])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default", {}, function () {
    return [_createElementVNode("input", _mergeProps({
      value: $props.modelValue
    }, _ctx.$attrs, _toHandlers($options.listeners), {
      "class": ["form-control", [{
        'is-valid': $props.valid === true
      }, {
        'is-invalid': $props.valid === false
      }, $props.inputClasses]],
      "aria-describedby": "addon-right addon-left"
    }), null, 16, _hoisted_4)];
  }), $props.addonRightIcon || _ctx.$slots.addonRight ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("span", _hoisted_6, [_renderSlot(_ctx.$slots, "addonRight", {}, function () {
    return [_createElementVNode("i", {
      "class": _normalizeClass($props.addonRightIcon)
    }, null, 2)];
  })])])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "infoBlock"), _renderSlot(_ctx.$slots, "helpBlock", {}, function () {
    return [$props.error ? (_openBlock(), _createElementBlock("div", {
      key: 0,
      "class": _normalizeClass(["text-danger invalid-feedback", {
        'mt-2': $options.hasIcon
      }]),
      style: {
        "display": "block"
      }
    }, _toDisplayString($props.error), 3)) : _createCommentVNode("", true)];
  })], 2);
}