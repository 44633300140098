import {
  get,
  post,
  put
} from "../axios";

// ============================================ commen ============================================

export const getIncomingDelegations = async (author) =>
  post(
    "https://sds.steemworld.org/delegations_api/getIncomingDelegations/" +
    author +
    "/100000/0"
  );
export const getOutgoingDelegations = async (author) =>
  post(
    "https://sds.steemworld.org/delegations_api/getOutgoingDelegations/" +
    author +
    "/100000/0"
  );
export const getExpiringDelegations = async (author) =>
  post(
    "https://sds.steemworld.org/delegations_api/getExpiringDelegations/" +
    author +
    "/100000/0"
  );