import "core-js/modules/es.string.link.js";
import "core-js/modules/es.function.name.js";
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "nav-item"
};
var _hoisted_2 = {
  "class": "nav-link-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");

  return _openBlock(), _createElementBlock("li", _hoisted_1, [_createVNode(_component_router_link, {
    to: $props.link.path,
    onClick: $options.linkClick,
    "class": "nav-link",
    target: $props.link.target,
    href: $props.link.path
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("i", {
        "class": _normalizeClass($props.link.icon)
      }, null, 2), _createElementVNode("span", _hoisted_2, _toDisplayString($props.link.name), 1)];
    }),
    _: 1
  }, 8, ["to", "onClick", "target", "href"])]);
}