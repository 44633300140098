import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["data"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_left_menu = _resolveComponent("left-menu");

  var _component_dashboard_navbar = _resolveComponent("dashboard-navbar");

  var _component_router_view = _resolveComponent("router-view");

  var _component_content_footer = _resolveComponent("content-footer");

  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["wrapper", {
      'nav-open': _ctx.$sidebar.showSidebar
    }])
  }, [_createVNode(_component_left_menu), _createElementVNode("div", {
    "class": "main-content",
    data: $data.sidebarBackground
  }, [_createVNode(_component_dashboard_navbar), _createElementVNode("div", {
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.toggleSidebar && $options.toggleSidebar.apply($options, arguments);
    })
  }, [_createVNode(_component_router_view), !_ctx.$route.meta.hideFooter ? (_openBlock(), _createBlock(_component_content_footer, {
    key: 0
  })) : _createCommentVNode("", true)])], 8, _hoisted_1)], 2);
}