import Card from "./Card.vue";
export default {
  name: "stats-card",
  components: {
    Card: Card
  },
  props: {
    type: {
      type: String,
      "default": "primary"
    },
    icon: String,
    title: String,
    subTitle: String,
    iconClasses: [String, Array]
  }
};