import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  "class": "container-fluid mt-2"
};
var _hoisted_2 = {
  "class": "container-fluid mt-2"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  key: 1,
  "class": "el-icon-plus avatar-uploader-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_base_header = _resolveComponent("base-header");

  var _component_el_upload = _resolveComponent("el-upload");

  var _component_el_form_item = _resolveComponent("el-form-item");

  var _component_el_input = _resolveComponent("el-input");

  var _component_el_option = _resolveComponent("el-option");

  var _component_el_select = _resolveComponent("el-select");

  var _component_el_link = _resolveComponent("el-link");

  var _component_el_button = _resolveComponent("el-button");

  var _component_el_form = _resolveComponent("el-form");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_base_header, {
    type: "gradient-success",
    "class": "pb-4 pb-4 pt-5 pt-md-5"
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form, {
    model: $data.profile,
    "label-width": "150px"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_form_item, {
        label: _ctx.$t('label.profileImage')
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_upload, {
            "class": "avatar-uploader",
            action: "abc",
            "show-file-list": false,
            "before-upload": $options.beforeAvatarUpload,
            "http-request": $options.imgAdd
          }, {
            "default": _withCtx(function () {
              return [$data.profile.profile_image ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: $data.profile.profile_image,
                "class": "avatar-community"
              }, null, 8, _hoisted_3)) : (_openBlock(), _createElementBlock("i", _hoisted_4))];
            }),
            _: 1
          }, 8, ["before-upload", "http-request"])];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: _ctx.$t('label.displayName')
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $data.profile.displayName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $data.profile.displayName = $event;
            })
          }, null, 8, ["modelValue"])];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: _ctx.$t('label.about')
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            type: "textarea",
            modelValue: $data.profile.about,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return $data.profile.about = $event;
            })
          }, null, 8, ["modelValue"])];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: _ctx.$t('label.location')
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            type: "textarea",
            modelValue: $data.profile.location,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return $data.profile.location = $event;
            })
          }, null, 8, ["modelValue"])];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: _ctx.$t('label.website')
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $data.profile.website,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
              return $data.profile.website = $event;
            })
          }, null, 8, ["modelValue"])];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: _ctx.$t('label.signature')
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $data.profile.signature,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
              return $data.profile.signature = $event;
            }),
            type: "textarea",
            rows: "6"
          }, null, 8, ["modelValue"])];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: _ctx.$t('label.blogPostRewards')
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_select, {
            modelValue: $data.profile.blogPostRewards,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
              return $data.profile.blogPostRewards = $event;
            }),
            placeholder: "Select"
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_el_option, {
                label: "50%/50%",
                value: $data.blogPostRewards[0]
              }, null, 8, ["value"]), _createVNode(_component_el_option, {
                label: "Power Up 100%",
                value: $data.blogPostRewards[1]
              }, null, 8, ["value"])];
            }),
            _: 1
          }, 8, ["modelValue"])];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: _ctx.$t('label.commentPostRewards')
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_select, {
            modelValue: $data.profile.commentPostRewards,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = function ($event) {
              return $data.profile.commentPostRewards = $event;
            }),
            placeholder: "Select"
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_el_option, {
                label: "50%/50%",
                value: $data.commentPostRewards[0]
              }, null, 8, ["value"]), _createVNode(_component_el_option, {
                label: "Power Up 100%",
                value: $data.commentPostRewards[1]
              }, null, 8, ["value"])];
            }),
            _: 1
          }, 8, ["modelValue"])];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: _ctx.$t('label.likeid')
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $data.profile.likeid,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = function ($event) {
              return $data.profile.likeid = $event;
            })
          }, null, 8, ["modelValue"]), _createVNode(_component_el_link, {
            type: "primary",
            href: "https://like.co/in/register",
            target: "_blank"
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode(_toDisplayString(_ctx.$t("label.registerLiker")), 1)];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, null, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_button, {
            loading: $data.saveLoad,
            type: "primary",
            onClick: $options.onSave
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode(_toDisplayString(_ctx.$t("button.save")), 1)];
            }),
            _: 1
          }, 8, ["loading", "onClick"])];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["model"])])])], 64);
}