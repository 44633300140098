import { PrivateKey } from "@boylikegirl/dsteem";

import crypto from "crypto";
import { extractLoginData } from "../unit/UserUtil";
import { uploadImage as axiosUploadImage } from "../apis/axios";
import { $STM_Config } from "../config";
import { compressAccurately } from "image-conversion";

/**
 * file:文件
 * config:{ //压缩配置
 * size     Number  指定压缩大小
 * scale    Number  相对于原始图像的缩放比例，范围0-10
 * width    Number  结果图像的宽度
 * height   Number  结果图像的高度
 * quality  Number  0-1 默认0.92
 * }
 *
 */
export async function compressConversion(file, config) {
  const resAvatarBlob = await compressAccurately(file, config);
  // console.log('压缩后',resAvatarBlob)
  const resAvatarFile = new window.File([resAvatarBlob], file.name, {
    type: file.type,
  });
  // console.log('压缩后的大小',resAvatarFile.size/1024)
  //compressAccurately有多个参数时传入对象
  //imageConversion.compressAccurately(file, {
  // size: 1024, //图片大小压缩到1024kb
  // width:1280 //宽度压缩到1280
  //}).then(res => {
  //resolve(res)
  //})

  if (!resAvatarFile.miniurl) {
    resAvatarFile.miniurl = await getBase64(resAvatarFile);
  }

  return resAvatarFile;
}

export const uploadImage = async function (file, callback) {
  let dataBs64, data;
  // recover from preview
  if (!file.miniurl) {
    file.miniurl = await getBase64(file);
  }
  let result = {
    code: 0,
    message: "",
  };
  let isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    file = await compressConversion(file, { size: 980 });
  }

  let commaIdx = file.miniurl.indexOf(",");
  dataBs64 = file.miniurl.substring(commaIdx + 1);
  data = new Buffer(dataBs64, "base64");

  const prefix = new Buffer("ImageSigningChallenge");
  const buf = Buffer.concat([prefix, data]);

  const formData = new FormData();
  formData.append("file", file);
  formData.append("filename", file._name);
  formData.append("filebase64", dataBs64);

  let loginData = extractLoginData();

  let author = loginData[0];
  let postingKey = loginData[1];

  let key = "";
  let sign = "";
  if (loginData[4] == "true") {
    const response = await new Promise((resolve) => {
      window.steem_keychain.requestSignBuffer(
        author,
        JSON.stringify(buf),
        "Posting",
        (response) => {
          resolve(response);
        }
      );
    });
    if (response.success) {
      sign = response.result;
    } else {
      return;
    }
  } else {
    key = PrivateKey.fromString(postingKey);
    const imageHash = crypto
      .createHash("sha256")
      .update("ImageSigningChallenge")
      .update(data)
      .digest();

    sign = key.sign(imageHash).toString();
  }

  let url = $STM_Config.img_proxy_prefix + author + "/" + sign;

  let imageUrl = await axiosUploadImage(url, formData, callback);

  return imageUrl;
};
export const getBase64 = async function (file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    let fileResult = "";
    reader.readAsDataURL(file); //开始转
    reader.onload = function () {
      fileResult = reader.result;
    }; //转 失败
    reader.onerror = function (error) {
      reject(error);
    }; //转 结束  咱就 resolve 出去
    reader.onloadend = function () {
      resolve(fileResult);
    };
  });
};

export default {
  uploadImage,
  getBase64,
};
