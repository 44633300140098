import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  "class": "progress-wrapper"
};
var _hoisted_2 = {
  key: 0,
  "class": "progress-info"
};
var _hoisted_3 = {
  key: 0,
  "class": "progress-label"
};
var _hoisted_4 = {
  key: 1,
  "class": "progress-percentage"
};
var _hoisted_5 = ["aria-valuenow"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.$slots.label || $props.label || _ctx.$slots.percentage || $props.showPercentage ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_ctx.$slots.label || $props.label ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("span", null, [_renderSlot(_ctx.$slots, "label", {}, function () {
    return [_createTextVNode(_toDisplayString($props.label), 1)];
  })])])) : _createCommentVNode("", true), _ctx.$slots.percentage || $props.showPercentage ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, "percentage", {}, function () {
    return [_createTextVNode(_toDisplayString($props.value) + " % ", 1)];
  })])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createElementVNode("div", {
    "class": "progress",
    style: _normalizeStyle("height: ".concat($props.height, "px"))
  }, [_createElementVNode("div", {
    "class": _normalizeClass(["progress-bar", $options.computedClasses]),
    role: "progressbar",
    "aria-valuenow": $props.value,
    "aria-valuemin": "0",
    "aria-valuemax": "100",
    style: _normalizeStyle("width: ".concat($props.value, "%;"))
  }, null, 14, _hoisted_5)], 4)]);
}