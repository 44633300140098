import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "modal-title",
  id: "modal-title-default"
};
var _hoisted_2 = {
  "class": "block"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  key: 1,
  "class": "el-icon-plus avatar-uploader-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");

  var _component_el_form_item = _resolveComponent("el-form-item");

  var _component_el_upload = _resolveComponent("el-upload");

  var _component_el_switch = _resolveComponent("el-switch");

  var _component_el_form = _resolveComponent("el-form");

  var _component_base_button = _resolveComponent("base-button");

  var _component_modal = _resolveComponent("modal");

  return _openBlock(), _createBlock(_component_modal, {
    show: $props.showModel,
    showClose: false
  }, {
    header: _withCtx(function () {
      return [_createElementVNode("h6", _hoisted_1, _toDisplayString(_ctx.title), 1)];
    }),
    footer: _withCtx(function () {
      return [_createVNode(_component_base_button, {
        type: !$data.isShowButton && !_ctx.isJoined ? 'secondary' : $data.isShowButton && !_ctx.isJoined ? 'primary' : 'success',
        onClick: $options.save
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t("button.save")), 1)];
        }),
        _: 1
      }, 8, ["type", "onClick"]), _createVNode(_component_base_button, {
        type: "link",
        "class": "ml-auto",
        onClick: $options.closeModel
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t("button.close")), 1)];
        }),
        _: 1
      }, 8, ["onClick"])];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_form, {
        model: $data.communities,
        "label-width": "80px"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_form_item, {
            label: _ctx.$t('label.communityTitle')
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_el_input, {
                modelValue: $data.communities.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
                  return $data.communities.title = $event;
                })
              }, null, 8, ["modelValue"])];
            }),
            _: 1
          }, 8, ["label"]), _createVNode(_component_el_form_item, {
            label: _ctx.$t('label.communityAbout')
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_el_input, {
                type: "textarea",
                modelValue: $data.communities.about,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
                  return $data.communities.about = $event;
                })
              }, null, 8, ["modelValue"])];
            }),
            _: 1
          }, 8, ["label"]), _createVNode(_component_el_form_item, {
            label: _ctx.$t('label.communityDescription')
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_el_input, {
                type: "textarea",
                modelValue: $data.communities.description,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
                  return $data.communities.description = $event;
                })
              }, null, 8, ["modelValue"])];
            }),
            _: 1
          }, 8, ["label"]), _createVNode(_component_el_form_item, {
            label: _ctx.$t('label.language')
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_el_input, {
                modelValue: $data.communities.lang,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
                  return $data.communities.lang = $event;
                })
              }, null, 8, ["modelValue"])];
            }),
            _: 1
          }, 8, ["label"]), _createVNode(_component_el_form_item, {
            label: _ctx.$t('label.communityRule')
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_el_input, {
                modelValue: $data.communities.flag_text,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = function ($event) {
                  return $data.communities.flag_text = $event;
                })
              }, null, 8, ["modelValue"])];
            }),
            _: 1
          }, 8, ["label"]), _createVNode(_component_el_form_item, {
            label: _ctx.$t('label.communityAvatarUrl')
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_el_upload, {
                "class": "avatar-uploader",
                action: "abc",
                "show-file-list": false,
                "before-upload": $options.beforeAvatarUpload,
                "http-request": $options.imgAdd
              }, {
                "default": _withCtx(function () {
                  return [$data.communities.avatar_url ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: $data.communities.avatar_url,
                    "class": "avatar-community"
                  }, null, 8, _hoisted_3)) : (_openBlock(), _createElementBlock("i", _hoisted_4))];
                }),
                _: 1
              }, 8, ["before-upload", "http-request"])];
            }),
            _: 1
          }, 8, ["label"]), _createVNode(_component_el_form_item, {
            label: "nsfw"
          }, {
            "default": _withCtx(function () {
              return [_createVNode(_component_el_switch, {
                modelValue: $data.communities.is_nsfw,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = function ($event) {
                  return $data.communities.is_nsfw = $event;
                })
              }, null, 8, ["modelValue"])];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["model"])])];
    }),
    _: 1
  }, 8, ["show"]);
}