import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "modal-title",
  id: "modal-title-default"
};
var _hoisted_2 = {
  "class": "block"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_slider = _resolveComponent("el-slider");

  var _component_base_button = _resolveComponent("base-button");

  var _component_modal = _resolveComponent("modal");

  return _openBlock(), _createBlock(_component_modal, {
    show: $props.showModel && $data.loginData,
    showClose: false
  }, {
    header: _withCtx(function () {
      return [_createElementVNode("h6", _hoisted_1, _toDisplayString(_ctx.$t("button.voteJoin")) + _toDisplayString($data.title), 1)];
    }),
    footer: _withCtx(function () {
      return [_createVNode(_component_base_button, {
        type: !$data.isShowButton && !$data.isJoined ? 'secondary' : $data.isShowButton && !$data.isJoined ? 'primary' : 'success',
        onClick: $options.votePost
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString($data.isJoined ? _ctx.$t("button.voteJoined") : _ctx.$t("button.voteJoin")), 1)];
        }),
        _: 1
      }, 8, ["type", "onClick"]), _createVNode(_component_base_button, {
        type: "link",
        "class": "ml-auto",
        onClick: $options.closeModel
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t("button.close")), 1)];
        }),
        _: 1
      }, 8, ["onClick"])];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_slider, {
        modelValue: $data.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $data.value = $event;
        }),
        "show-input": ""
      }, null, 8, ["modelValue"])])];
    }),
    _: 1
  }, 8, ["show"]);
}