import dsteem from "../unit/DsteemUtil";
/* import { login } from "../apis/services/steemActivity"; */

/**
 *
 * @returns {boolean}
 */
export const isLoggedIn = () =>
  typeof localStorage !== "undefined" && !!localStorage.getItem("autopost2");

/**
 *
 * @returns {string}
 */
export const packLoginData = function (
  username,
  password,
  memoWif,
  login_owner_pubkey,
  login_with_keychain,
  rememberMe
) {
  let loginData = new Buffer(
    `${username}\t${password}\t${memoWif || ""}\t${login_owner_pubkey || ""}\t${login_with_keychain || ""
    }`
  ).toString("hex");
  sessionStorage.setItem("autopost2", loginData);
  sessionStorage.setItem("isLogin", true);

  if (rememberMe) {
    localStorage.setItem("autopost2", loginData);
    localStorage.setItem("isLogin", true);
  }
};
/**
 *
 * @returns {array} [username, password, memoWif, login_owner_pubkey, login_with_keychain]
 */
export const extractLoginData = function () {
  let data = sessionStorage.getItem("autopost2");

  if (!data) {
    data = localStorage.getItem("autopost2");
  }
  if (!data) {
    return null;
  }
  sessionStorage.setItem("autopost2", data);
  sessionStorage.setItem("isLogin", true);
  localStorage.setItem("isLogin", true);
  return new Buffer(data, "hex").toString().split("\t");
};
/**
 *
 * @returns
 */
export const logout = function () {
  sessionStorage.removeItem("autopost2", null);
  sessionStorage.removeItem("isLogin", null);
  localStorage.removeItem("isLogin", null);
  localStorage.removeItem("autopost2", null);

  /* sessionStorage.clear();
  localStorage.clear(); */
};

/**通过用户名密码登录 */
export const loginWithUserNameAndPassword = async function (
  username,
  password
) {
  var i = {
    id: "login",
    json:
      '{"dapp":"https://boylikegirl.club","version":"0.1","type": "login", "data": {"author": "' +
      username +
      '"}}',
    required_auths: [],
    required_posting_auths: [username],
  };

  let operations = [["custom_json", i]];

  let expireTime = 60 * 1000; //1 min
  let prop = await dsteem.getDynamicGlobalProperties();

  let op = {
    ref_block_num: prop.head_block_number, //reference head block number required by tapos (transaction as proof of stake)
    ref_block_prefix: Buffer.from(prop.head_block_id, "hex").readUInt32LE(4), //reference buffer of block id as prefix
    expiration: new Date(Date.now() + expireTime).toISOString().slice(0, -5), //set expiration time for transaction (+1 min)
    operations: operations, //example of operation object for vote

    extensions: [], //extensions for this transaction
  };

  let stx = dsteem.signTx(op, password);

  let soResult;
  try {
    soResult = await dsteem.sendOperations(operations, password);
  } catch (ex) {
    /**** */
    soResult = ex;
  }

  /*废除  let loginData = {
    loginData: Buffer.from(JSON.stringify(stx)).toString("base64"),
  }; */

  /* 发送到后台验证
   let loginData = {
    loginData: JSON.stringify(stx),
  };
  let result = await login(loginData); */

  let result = {
    code: -1,
    message: "userOrPasswordError",
  };
  let isLoginFail = soResult.name == "AssertionError" || soResult.jse_info;

  if (stx.signatures && !isLoginFail) {
    result = {
      code: 0,
      message: "loginScuccess",
    };
    return result;
  }

  return result;
};
/**通过SteemKeychain登录 */
export const loginWithSteemKeychain = function (username, callback) {
  window.steem_keychain.requestCustomJson(
    username,
    "login",
    "Posting",
    '{"dapp":"https://boylikegirl.club","version":"0.1","type": "login", "data": {"author": "' +
    username +
    '"}}',
    "Login",
    function (response) {
      if (response.result) {
        /*废除  let loginData = {
          loginData: Buffer.from(JSON.stringify(response.result)).toString(
            "base64"
          ),
        }; */

        /**发送到后台 */
        /*
        let loginData = {
          loginData: JSON.stringify(response.result),
        };
        login(loginData).then((res) => {
          callback(res);
        }); */
        let res = {
          code: 0,
          message: "loginScuccess",
        };
        callback(res);
      } else {
        let res = {
          code: -1,
          message: "userOrPasswordError",
        };
        callback(res);
      }
    }
  );
};

/**
 * 获取用户信息
 *
 */
export const getAccount = async function (username) {
  let result = await dsteem.getAccounts(username);

  let posting_json_metadata, json_metadata;
  if (result && result[0] && result[0].posting_json_metadata) {
    posting_json_metadata = JSON.parse(
      result[0].posting_json_metadata
        ? result[0].posting_json_metadata
        : result[0].json_metadata
    );
  }
  if (result && result[0] && result[0].json_metadata) {
    json_metadata = JSON.parse(
      result[0].json_metadata
        ? result[0].json_metadata
        : result[0].posting_json_metadata
    );
  }

  let profile = {};

  if (posting_json_metadata || json_metadata)
    profile = { ...json_metadata?.profile, ...posting_json_metadata?.profile };
  if (result && result[0]) {
    profile.post_count = result[0].post_count;
    profile.voting_power = result[0].voting_power;
    profile.balance = result[0].balance.replace(" STEEM", "");
    profile.sbd_balance = result[0].sbd_balance.replace(" SBD", "");

    profile.vesting_shares = result[0].vesting_shares;
    profile.to_withdraw = result[0].to_withdraw;
    profile.withdrawn = result[0].withdrawn;
    profile.delegated_vesting_shares = result[0].delegated_vesting_shares;
    profile.received_vesting_shares = result[0].received_vesting_shares;
    profile.name = result[0].name;
    profile.next_vesting_withdrawal = result[0].next_vesting_withdrawal;
    profile.reward_sbd_balance = result[0].reward_sbd_balance;
    profile.reward_steem_balance = result[0].reward_steem_balance;
    profile.reward_vesting_balance = result[0].reward_vesting_balance;
    profile.reward_vesting_steem = result[0].reward_vesting_steem;
    profile.posting = result[0].posting;
  }
  localStorage.setItem("blogPostRewards", profile.blogPostRewards ? profile.blogPostRewards : 0);
  localStorage.setItem("commentPostRewards", profile.commentPostRewards ? profile.commentPostRewards : 0);
  return profile;
};
/**
 * 获取用户信息
 *
 */
export const getProfile = function (json) {
  let json_metadata;

  json_metadata = JSON.parse(json);

  let profile = {};

  profile = {
    ...json_metadata.profile,
  };
  return profile;
};

/**
 * 计算声望
 * @param {} input
 * @returns
 */
export const getAccountReputation = function (input) {
  var reputation = repLog10(input); //account.reputation
  return reputation;
};

function log10(str) {
  const leadingDigits = parseInt(str.substring(0, 4));
  const log = Math.log(leadingDigits) / Math.LN10 + 0.00000001;
  const n = str.length - 1;
  return n + (log - parseInt(log));
}

function repLog10(rep2) {
  if (rep2 == null) return rep2;
  let rep = String(rep2);
  const neg = rep.charAt(0) === "-";
  rep = neg ? rep.substring(1) : rep;

  let out = log10(rep);
  if (isNaN(out)) out = 0;
  out = Math.max(out - 9, 0); // @ -9, $0.50 earned is approx magnitude 1
  out = (neg ? -1 : 1) * out;
  out = out * 9 + 25; // 9 points per magnitude. center at 25
  // base-line 0 to darken and < 0 to auto hide (grep rephide)
  out = parseInt(out);
  return out;
}
export default {
  isLoggedIn,
  extractLoginData,
  loginWithUserNameAndPassword,
  loginWithSteemKeychain,
  getAccount,
  logout,
  getProfile,
  getAccountReputation,
};
