import "core-js/modules/es.number.constructor.js";
export default {
  inheritAttrs: false,
  name: "base-input",
  props: {
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)"
    },
    valid: {
      type: Boolean,
      description: "Whether is valid",
      "default": undefined
    },
    label: {
      type: String,
      description: "Input label (text before input)"
    },
    error: {
      type: String,
      description: "Input error (below input)"
    },
    formClasses: {
      type: String,
      description: "Form css classes"
    },
    labelClasses: {
      type: String,
      description: "Input label css classes"
    },
    inputClasses: {
      type: String,
      description: "Input css classes"
    },
    modelValue: {
      type: [String, Number],
      description: "Input value"
    },
    addonRightIcon: {
      type: String,
      description: "Addon right icon"
    },
    addonLeftIcon: {
      type: String,
      description: "Addont left icon"
    }
  },
  data: function data() {
    return {
      focused: false
    };
  },
  computed: {
    listeners: function listeners() {
      return {
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      };
    },
    hasIcon: function hasIcon() {
      var _this$$slots = this.$slots,
          addonRight = _this$$slots.addonRight,
          addonLeft = _this$$slots.addonLeft;
      return addonRight !== undefined || addonLeft !== undefined || this.addonRightIcon !== undefined || this.addonLeftIcon !== undefined;
    }
  },
  methods: {
    updateValue: function updateValue(evt) {
      var value = evt.target.value;
      this.$emit("update:modelValue", value);
    },
    onFocus: function onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur: function onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    }
  }
};