import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  "class": "no-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _directive_click_outside = _resolveDirective("click-outside");

  return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent($props.tag), {
    "class": _normalizeClass(["dropdown", [{
      show: $data.isOpen
    }, {
      dropdown: $props.direction === 'down'
    }, {
      dropup: $props.direction === 'up'
    }]]),
    "aria-haspopup": "true",
    "aria-expanded": $data.isOpen,
    onClick: $options.toggleDropDown
  }, {
    "default": _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "title", {}, function () {
        return [_createElementVNode("a", {
          "class": _normalizeClass(["dropdown-toggle nav-link", {
            'no-caret': $props.hideArrow
          }]),
          "data-toggle": "dropdown"
        }, [_createElementVNode("i", {
          "class": _normalizeClass($props.icon)
        }, null, 2), _createElementVNode("span", _hoisted_1, _toDisplayString($props.title), 1)], 2)];
      }), _createElementVNode("ul", {
        "class": _normalizeClass(["dropdown-menu", [{
          'dropdown-menu-right': $props.position === 'right'
        }, {
          show: $data.isOpen
        }, $props.menuClasses]]),
        ref: "menu"
      }, [_renderSlot(_ctx.$slots, "default")], 2)];
    }),
    _: 3
  }, 8, ["class", "aria-expanded", "onClick"])), [[_directive_click_outside, $options.closeDropDown]]);
}