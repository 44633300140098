import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4dcaeb01"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  id: "comment",
  ref: "comment"
};
var _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_comment_form = _resolveComponent("comment-form");

  var _component_comment_item = _resolveComponent("comment-item");

  var _component_comment_list = _resolveComponent("comment-list");

  var _component_vote_model = _resolveComponent("vote-model");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [$props.user.name ? (_openBlock(), _createBlock(_component_comment_form, {
    key: 0,
    "upload-img": $props.uploadImg,
    onFormSubmit: $options.formSubmit,
    rootPost: $props.rootPost
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("img", {
        "class": "avatar mr-2",
        src: $props.user.avatar || '/img/theme/user-static.png'
      }, null, 8, _hoisted_2)];
    }),
    _: 1
  }, 8, ["upload-img", "onFormSubmit", "rootPost"])) : _createCommentVNode("", true), $data.cacheData.length > 0 ? (_openBlock(), _createBlock(_component_comment_list, {
    key: 1,
    ref: "comment-list"
  }, {
    "default": _withCtx(function () {
      return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cacheData, function (comment, i) {
        return _openBlock(), _createBlock(_component_comment_item, {
          id: "comment-".concat(i),
          key: "comment-".concat(i),
          ref_for: true,
          ref: "comment-".concat(i),
          user: $props.user,
          comment: comment,
          onCommentReply: $options.hasForm,
          onCommentLike: $options.handleCommentLike,
          onCommentDelete: $options.handleCommentDelete
        }, {
          "default": _withCtx(function (_ref) {
            var id = _ref.id;
            return [$data.forms.includes(id) ? (_openBlock(), _createBlock(_component_comment_form, {
              key: 0,
              id: id,
              parent: comment,
              placeholder: "".concat(_ctx.$t('label.reply'), "@").concat(comment.user.name, "..."),
              "upload-img": $props.uploadImg,
              onFormSubmit: $options.formSubmit,
              onFormDelete: $options.deleteForm
            }, null, 8, ["id", "parent", "placeholder", "upload-img", "onFormSubmit", "onFormDelete"])) : _createCommentVNode("", true)];
          }),
          subList: _withCtx(function (_ref2) {
            var parentId = _ref2.parentId;
            return [_createVNode(_component_comment_list, {
              sub: ""
            }, {
              "default": _withCtx(function () {
                return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(comment.children, function (child, j) {
                  return _openBlock(), _createBlock(_component_comment_item, {
                    id: "".concat(parentId, "-").concat(j),
                    key: "".concat(parentId, "-").concat(j),
                    ref_for: true,
                    ref: "".concat(parentId, "-").concat(j),
                    comment: child,
                    user: $props.user,
                    parent: comment,
                    onCommentReply: $options.hasForm,
                    onCommentLike: $options.handleCommentLike,
                    onCommentDelete: $options.handleCommentDelete
                  }, {
                    "default": _withCtx(function () {
                      return [$data.forms.includes("".concat(parentId, "-").concat(j)) ? (_openBlock(), _createBlock(_component_comment_form, {
                        key: 0,
                        id: "".concat(parentId, "-").concat(j),
                        comment: child,
                        parent: comment,
                        placeholder: "".concat(_ctx.$t('label.reply')).concat(child.user && child.user.name, "..."),
                        "upload-img": $props.uploadImg,
                        onFormDelete: $options.deleteForm,
                        onFormSubmit: $options.formSubmit
                      }, null, 8, ["id", "comment", "parent", "placeholder", "upload-img", "onFormDelete", "onFormSubmit"])) : _createCommentVNode("", true)];
                    }),
                    _: 2
                  }, 1032, ["id", "comment", "user", "parent", "onCommentReply", "onCommentLike", "onCommentDelete"]);
                }), 128))];
              }),
              _: 2
            }, 1024)];
          }),
          _: 2
        }, 1032, ["id", "user", "comment", "onCommentReply", "onCommentLike", "onCommentDelete"]);
      }), 128))];
    }),
    _: 1
  }, 512)) : _createCommentVNode("", true)], 512), _createVNode(_component_vote_model, {
    ref: "voteModel",
    post: $data.blogs,
    showModel: $data.showModel,
    onCloseModel: $options.closeModel,
    onVotePost: $options.votePost
  }, null, 8, ["post", "showModel", "onCloseModel", "onVotePost"])], 64);
}