import _defineProperty from "/root/steemboylikegirlClub/gitproject/steemboylikegirlclub/steemboylikegirlclub/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["card", [{
      'card-lift--hover': $props.hover
    }, {
      shadow: $props.shadow
    }, _defineProperty({}, "shadow-".concat($props.shadowSize), $props.shadowSize), _defineProperty({}, "bg-gradient-".concat($props.gradient), $props.gradient), _defineProperty({}, "bg-".concat($props.type), $props.type)]])
  }, [_ctx.$slots.header ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    "class": _normalizeClass(["card-header", $props.headerClasses])
  }, [_renderSlot(_ctx.$slots, "header")], 2)) : _createCommentVNode("", true), !$props.noBody ? (_openBlock(), _createElementBlock("div", {
    key: 1,
    "class": _normalizeClass(["card-body", $props.bodyClasses])
  }, [_renderSlot(_ctx.$slots, "default")], 2)) : _createCommentVNode("", true), $props.noBody ? _renderSlot(_ctx.$slots, "default", {
    key: 2
  }) : _createCommentVNode("", true), _ctx.$slots.footer ? (_openBlock(), _createElementBlock("div", {
    key: 3,
    "class": _normalizeClass(["card-footer", $props.footerClasses])
  }, [_renderSlot(_ctx.$slots, "footer")], 2)) : _createCommentVNode("", true)], 2);
}