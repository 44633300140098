import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue";
var _hoisted_1 = {
  "class": "vuejs-loadmore-wrap"
};
var _hoisted_2 = {
  key: 0
};
var _hoisted_3 = {
  key: 1,
  "class": "vuejs-refresh-text"
};
var _hoisted_4 = {
  "class": "vuejs-loadmore",
  ref: "placeholder"
};
var _hoisted_5 = {
  key: 0,
  "class": "vuejs-loadmore-loading"
};
var _hoisted_6 = {
  key: 1,
  "class": "vuejs-loadmore-finished-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Loading = _resolveComponent("Loading");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    ref: "track",
    "class": "vuejs-refresh-track",
    style: _normalizeStyle({
      transform: $data.distance ? "translate3d(0, ".concat($data.distance, "px, 0)") : '',
      webkitTransform: $data.distance ? "translate3d(0, ".concat($data.distance, "px, 0)") : '',
      transitionDuration: "".concat($data.duration, "ms")
    })
  }, [_createElementVNode("div", {
    "class": "vuejs-refresh-head",
    style: _normalizeStyle($options.headStyle)
  }, [$data.status === 'refresh' ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_Loading, null, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString($options.genStatus), 1)];
    }),
    _: 1
  })])) : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($options.genStatus), 1))], 4), _renderSlot(_ctx.$slots, "default"), _createElementVNode("div", _hoisted_4, [$data.loadLoading && !$props.finished && !$props.error ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_Loading, null, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString($props.loadingText || _ctx.$t("loadmore.loading")), 1)];
    }),
    _: 1
  })])) : _createCommentVNode("", true), $props.finished ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($props.finishedText || _ctx.t("loadmore.finished")), 1)) : _createCommentVNode("", true), $props.error ? (_openBlock(), _createElementBlock("div", {
    key: 2,
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.clickErrorText && $options.clickErrorText.apply($options, arguments);
    }),
    "class": "vuejs-loadmore-error-text"
  }, _toDisplayString($props.errorText || _ctx.t("loadmore.error")), 1)) : _createCommentVNode("", true)], 512)], 4)]);
}