import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.index-of.js";
// Mixins
import { TouchMixin } from "../mixins/touch";
import { BindEventMixin } from "../mixins/bind-event";
import { TimeoutMixin } from "../mixins/timer"; // utils

import { preventDefault } from "../utils/event";
import { getScroller, getScrollTop } from "../utils/scroll";
import { throttle } from "../utils/throttle"; // Icon

import Loading from "../icon";
var TEXT_STATUS = ["pulling", "loosing", "refresh", "success"];
export default {
  name: "loadmore",
  mixins: [TouchMixin, BindEventMixin(function (bind) {
    if (!this.scroller) {
      this.scroller = getScroller(this.$el);
    } // scroll节流


    bind(this.scroller, "scroll", throttle(this.checkSroll, 200));
  }), TimeoutMixin],
  components: {
    Loading: Loading
  },
  props: {
    // 下拉刷新
    onRefresh: Function,
    pullingText: {
      type: String
    },
    loosingText: {
      type: String
    },
    refreshText: {
      type: String
    },
    successText: {
      type: String
    },
    showSuccessText: {
      type: Boolean,
      "default": true
    },
    pullDistance: {
      type: [Number, String],
      "default": 50
    },
    headHeight: {
      type: [Number, String],
      "default": 50
    },
    animationDuration: {
      type: [Number, String],
      "default": 200
    },
    // 上拉加载
    onLoadmore: Function,
    immediateCheck: {
      type: Boolean,
      "default": false
    },
    loadOffset: {
      type: [Number, String],
      "default": 50
    },
    finished: Boolean,
    error: Boolean,
    loadingText: {
      type: String
    },
    finishedText: {
      type: String
    },
    errorText: {
      type: String
    }
  },
  data: function data() {
    return {
      status: "normal",
      // 下拉状态
      distance: 0,
      // 下拉距离
      duration: 0,
      // 动画时间
      scroller: null,
      // 滚动容器元素
      loadLoading: false // loadmore loading

    };
  },
  mounted: function mounted() {
    // 绑定touch事件
    this.bindTouchEvent(this.$refs.track); // 获取$el最近一个父级可滚动容器元素

    this.scroller = getScroller(this.$el); // 是否立即检查

    if (this.immediateCheck) {
      this.checkSroll();
    }
  },
  computed: {
    touchable: function touchable() {
      return this.status !== "refresh" && this.status !== "success" && this.onRefresh;
    },
    headStyle: function headStyle() {
      return this.headHeight !== 50 ? {
        height: "".concat(this.headHeight, "px")
      } : {};
    },
    genStatus: function genStatus() {
      var status = this.status;
      var text = this["".concat(status, "Text")] || this.$t("refresh.".concat(status));
      return TEXT_STATUS.indexOf(status) !== -1 ? text : "";
    }
  },
  methods: {
    checkPullStart: function checkPullStart(event) {
      // 父级滚动元素的滚动条在顶部位置
      this.ceiling = getScrollTop(this.scroller) === 0;

      if (this.ceiling) {
        this.duration = 0;
        this.touchStart(event);
      }
    },
    onTouchStart: function onTouchStart(event) {
      if (!this.touchable) {
        return;
      }

      this.checkPullStart(event);
    },
    onTouchMove: function onTouchMove(event) {
      if (!this.touchable) {
        return;
      }

      if (!this.ceiling) {
        // 滚动容器不在顶部回拉时，需触发touchStart
        this.checkPullStart(event);
      }

      this.touchMove(event);

      if (this.ceiling && this.deltaY >= 0 && this.direction === "vertical") {
        // 阻止默认行为(如下拉网页也会默认下拉的行为)
        preventDefault(event);
        this.setStatus(this.ease(this.deltaY));
      }
    },
    onTouchEnd: function onTouchEnd() {
      var _this = this;

      if (this.deltaY && this.touchable) {
        this.duration = this.animationDuration;

        if (this.status === "loosing") {
          this.showRefreshTip(); // ensure value change can be watched

          this.$nextTick(function () {
            _this.onRefresh(_this.refreshDone);
          });
        } else {
          this.setStatus(0);
        }
      }
    },
    ease: function ease(distance) {
      var pullDistance = +(this.pullDistance || this.headHeight);

      if (distance > pullDistance) {
        if (distance < pullDistance * 2) {
          distance = pullDistance + (distance - pullDistance) / 2;
        } else {
          distance = pullDistance * 1.5 + (distance - pullDistance * 2) / 4;
        }
      }

      return Math.round(distance);
    },
    setStatus: function setStatus(distance) {
      var isRefresh = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var status;

      if (isRefresh) {
        status = "refresh";
      } else if (distance === 0) {
        status = "normal";
      } else {
        status = distance < (this.pullDistance || this.headHeight) ? "pulling" : "loosing";
      }

      this.distance = distance;

      if (status !== this.status) {
        this.status = status;
      }
    },
    refreshDone: function refreshDone() {
      var _this2 = this;

      if (this.showSuccessText) {
        this.timeout(this.showSuccessTip, 500);
      } else {
        this.timeout(function () {
          return _this2.setStatus(0);
        }, 500);
      }
    },
    showRefreshTip: function showRefreshTip() {
      this.setStatus(+this.headHeight, true);
    },
    showSuccessTip: function showSuccessTip() {
      var _this3 = this;

      this.status = "success";
      this.timeout(function () {
        return _this3.setStatus(0);
      }, 1000);
    },
    checkSroll: function checkSroll() {
      var _this4 = this;

      this.$nextTick(function () {
        if (_this4.loadLoading || !_this4.onLoadmore || _this4.finished || _this4.error) {
          return;
        }

        var scroller = _this4.scroller,
            loadOffset = _this4.loadOffset;
        var scrollerRect;

        if (scroller.getBoundingClientRect) {
          scrollerRect = scroller.getBoundingClientRect();
        } else {
          scrollerRect = {
            top: 0,
            bottom: scroller.innerHeight
          };
        }

        var scrollerHeight = scrollerRect.bottom - scrollerRect.top;

        if (!scrollerHeight) {
          return false;
        }

        var placeholderRect = _this4.$refs.placeholder.getBoundingClientRect(); // 取绝对值，placeholderRect在scrollerRect容器的正负loadOffset区间则达到底部


        var bottomReached = Math.abs(placeholderRect.bottom - scrollerRect.bottom) <= loadOffset;

        if (bottomReached) {
          _this4.loadLoading = true;

          _this4.timeout(function () {
            return _this4.onLoadmore(_this4.loadmoreDone);
          }, 500);
        }
      });
    },
    clickErrorText: function clickErrorText() {
      var _this5 = this;

      this.$emit("update:error", false);
      this.loadLoading = true;
      this.timeout(function () {
        return _this5.onLoadmore(_this5.loadmoreDone);
      }, 500);
    },
    loadmoreDone: function loadmoreDone() {
      this.loadLoading = false;
    }
  }
};