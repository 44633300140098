import _defineProperty from "/root/steemboylikegirlClub/gitproject/steemboylikegirlclub/steemboylikegirlclub/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.function.name.js";
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_link = _resolveComponent("router-link");

  var _directive_click_outside = _resolveDirective("click-outside");

  return _openBlock(), _createElementBlock("nav", {
    "class": _normalizeClass(["navbar", [{
      'navbar-expand-md': $props.expand
    }, {
      'navbar-transparent': $props.transparent
    }, _defineProperty({}, "bg-".concat($props.type), $props.type)]])
  }, [_createElementVNode("div", {
    "class": _normalizeClass($props.containerClasses)
  }, [_renderSlot(_ctx.$slots, "brand", {}, function () {
    return [_createVNode(_component_router_link, {
      to: _ctx.$route.path,
      "class": "h4 mb-0 text-white d-none d-lg-inline-block"
    }, {
      "default": _withCtx(function () {
        return [_createTextVNode(_toDisplayString(_ctx.$t("menu." + _ctx.$route.name)), 1)];
      }),
      _: 1
    }, 8, ["to"])];
  }), _withDirectives((_openBlock(), _createElementBlock("div", {
    "class": _normalizeClass(["collapse navbar-collapse", {
      show: $data.toggled
    }]),
    id: $props.contentId
  }, [_renderSlot(_ctx.$slots, "default", {
    closeMenu: $options.closeMenu
  })], 10, _hoisted_1)), [[_directive_click_outside, $options.closeMenu]])], 2)], 2);
}