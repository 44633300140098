import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "container-fluid d-flex align-items-center"
};
var _hoisted_2 = {
  "class": "row"
};
var _hoisted_3 = {
  "class": "col-lg-10 col-md-12"
};
var _hoisted_4 = {
  "class": "text-white mt-0 mb-5"
};
var _hoisted_5 = {
  "class": "container-fluid mt-2"
};
var _hoisted_6 = {
  "class": "container-fluid mt-2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_base_header = _resolveComponent("base-header");

  var _component_el_input = _resolveComponent("el-input");

  var _component_el_form_item = _resolveComponent("el-form-item");

  var _component_el_button = _resolveComponent("el-button");

  var _component_el_form = _resolveComponent("el-form");

  var _component_el_alert = _resolveComponent("el-alert");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_base_header, {
    type: "gradient-success",
    "class": "pb-6 pt-2 pt-md-7"
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("label.regIntro")), 1)])])])];
    }),
    _: 1
  }), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_form, {
    "label-width": "150px",
    "status-icon": "",
    rules: $data.rules
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_form_item, {
        label: _ctx.$t('label.InputUserName'),
        prop: "userName"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $data.userName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $data.userName = $event;
            }),
            maxlength: "16"
          }, null, 8, ["modelValue"])];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, {
        label: _ctx.$t('label.email'),
        prop: "email"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $data.email,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return $data.email = $event;
            })
          }, null, 8, ["modelValue"])];
        }),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_form_item, null, {
        "default": _withCtx(function () {
          return [!$data.isSendEmail ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            loading: $data.saveLoad,
            type: "primary",
            onClick: $options.onSave,
            disabled: !$data.isVerify || !$data.isVerifyEmail
          }, {
            "default": _withCtx(function () {
              return [_createTextVNode(_toDisplayString(_ctx.$t("button.submit")), 1)];
            }),
            _: 1
          }, 8, ["loading", "onClick", "disabled"])) : _createCommentVNode("", true)];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["rules"]), $data.isSendEmail ? (_openBlock(), _createBlock(_component_el_alert, {
    key: 0,
    title: _ctx.$t('success.regVerifyEmailSend'),
    type: "success",
    closable: false
  }, null, 8, ["title"])) : _createCommentVNode("", true)])])], 64);
}