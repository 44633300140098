import UserLoginView from "../components/UserLoginView.vue";
export default {
  components: {
    UserLoginView: UserLoginView
  },
  data: function data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      isOtherLogin: false
    };
  },
  methods: {
    toggleSidebar: function toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar: function hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu: function toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};